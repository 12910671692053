/* eslint-disable no-useless-escape */
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import ShareIcons from "../_helpers/Icons/shareIcons";

const ShareDropDown = ({
  open,
  setShowDropDown,
  url,
  content = "",
  variant = "card",
  isGiftCard = false,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  const variantClasses = {
    card: {
      container: "w-full absolute top-8 sm:top-10 md:top-16",
      dropdown:
        "flex share-dropdown flex-row justify-center sm:justify-around items-center sm:py-8 py-4 sm:px-12 bg-[#020052] h-11 sm:h-20",
      shareText: "hidden sm:block text-base text-white",
      iconContainer: "flex flex-row w-40 sm:w-60 items-center justify-around",
      copiedClipboard: "text-xs sm:text-sm font-semibold max-w-[260px]",
      iconLink: "h-10 w-5 sm:h-20 sm:w-14 flex items-center justify-center",
      iconText: "hidden",
      iconLinkHover: "hover:bg-theme-blue",
    },
    details: {
      container: "w-64 absolute top-[2.8rem] left-[-6.8rem]",
      dropdown: `flex ${
        isGiftCard
          ? "share-dropdown-details-gift-card"
          : "share-dropdown-details"
      } flex-row bg-[#020052]`,
      shareText: "text-base text-white hidden",
      iconContainer: "flex flex-col w-full",
      copiedClipboard: "text-xs sm:text-sm font-semibold max-w-[260px]",
      iconLink: "py-4 px-8 flex flex-row items-center",
      iconText: "ml-5 whitespace-nowrap text-white",
      iconLinkHover: "hover:bg-theme-blue",
    },
  };

  useEffect(() => {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };
    if (window.mobileCheck()) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  // function to get share links for social media platforms
  const getShareLinks = (title) => {
    switch (title) {
      case "WhatsApp":
        return `https://wa.me/?text=${content}${url}`;
      case "Messages":
        return `sms://?&body=${content}${url}`;
      case "Twitter":
        return `https://twitter.com/intent/tweet?text=${content}${url}`;
      case "Messenger":
        if (isMobile) {
          return `fb-messenger://share/?link=${url}`;
        } else return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
      case "Email":
        return `mailto:?subject=Check this out&body=${content}${url}`;
      default:
        return "#";
    }
  };

  const copyLinkToClipboard = (e) => {
    navigator.clipboard
      .writeText(url)
      .then(async () => {
        showTooltip(e);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showTooltip = (e) => {
    ReactTooltip.hide(e.target);
  };

  if (!open) return null;
  return (
    <div
      className={variantClasses[variant].container}
      onMouseLeave={setShowDropDown}
    >
      <div className={variantClasses[variant].dropdown}>
        <span className={variantClasses[variant].shareText}>Share on</span>
        <div className={variantClasses[variant].iconContainer}>
          <div className={variantClasses[variant].iconLinkHover}>
            <a
              className={`${variantClasses[variant].iconLink} ${variantClasses[variant].iconLinkHover}`}
              href={getShareLinks("Messenger")}
              target="_blank"
              rel="noreferrer"
            >
              <ShareIcons.FBShareIcon />
              <span className={variantClasses[variant].iconText}>
                Share on Facebook
              </span>
            </a>
          </div>
          <div className={variantClasses[variant].iconLinkHover}>
            <a
              className={`${variantClasses[variant].iconLink} ${variantClasses[variant].iconLinkHover}`}
              href={getShareLinks("Twitter")}
              target="_blank"
              rel="noreferrer"
            >
              <ShareIcons.TwitterShareIcon />
              <span className={variantClasses[variant].iconText}>
                Share on Twitter
              </span>
            </a>
          </div>
          <div className={variantClasses[variant].iconLinkHover}>
            <a
              className={`${variantClasses[variant].iconLink} ${variantClasses[variant].iconLinkHover}`}
              href={getShareLinks("WhatsApp")}
              target="_blank"
              rel="noreferrer"
            >
              <ShareIcons.WhatsappShareIcon />
              <span className={variantClasses[variant].iconText}>
                Share on Whatsapp
              </span>
            </a>
          </div>
          <div className={variantClasses[variant].iconLinkHover}>
            <button
              className={`block focus:outline-none ${variantClasses[variant].iconLink} ${variantClasses[variant].iconLinkHover}`}
              data-tip
              data-for="Clipboard_Link"
              data-event="click"
            >
              <ShareIcons.LinkShareIcon />
              <span className={variantClasses[variant].iconText}>
                Copy Link
              </span>
            </button>
          </div>
          <ReactTooltip
            place="bottom"
            id={"Clipboard_Link"}
            effect="solid"
            borderColor="#1c1794"
            border={true}
            textColor="#1c1794"
            backgroundColor="#ffffff"
            delayHide={1000}
            afterShow={copyLinkToClipboard}
          >
            <div className={variantClasses[variant].copiedClipboard}>
              Link copied to clipboard
            </div>
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default ShareDropDown;
