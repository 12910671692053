import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import queryString from "query-string";
import Button from "./Button";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useCart } from "../services/cart";
import CartEmptyState from "./CartEmptyState";
import CartHeading from "./CartHeading";
import TryAtHome from "./CartTryAtHome";
import CartMainItem from "./CartMainItem";
import Link from "next/link";
import { CloseIcon, TipIcon } from "../_helpers/Icons/miscellaneousIcons";
import useDialog from "../hooks/useDialog";
import classNames from "classnames";
import { getOrdinal, getRakutenParams } from "../_helpers/functions";
import { monthsInLong } from "../services/constants";
import ToolTip from "../components/DiamondSelector/components/Tip";
import useLocalePrice from "../hooks/useLocalePrice";

const Cart = ({
  ringItems,
  studItems,
  diamondItems,
  replicaItems,
  ringWithDiamond,
  necklaceItems,
  necklaceWithDiamond,
  braceletItems,
  giftCardItems,
  onRemove,
  onCheckOut,
  isEmpty,
  maxReplicaItems,
  totalPrice,
  isItemIncomplete,
  isTryHomeIncomplete,
  checkOutLoading,
  showToastMessage,
  hasRingSize,
  shippingDate,
  haveSoldDiamonds,
  similarDiamondLink,
}) => {
  const { showCart, setShowCart } = useCart();
  const { dialogType } = useDialog();
  const router = useRouter();
  const [tipActive, setTipActive] = useState(false);
  const [tipInfo, setTipInfo] = useState({});
  const { formatPrice } = useLocalePrice();

  const containerRef = useOutsideClick(() => {
    setShowCart({ value: false, showId: "" });
  }, showCart.value);

  const scrollHeight = typeof window !== "undefined" && window?.scrollY;

  /* eslint-disable-next-line */
  function shareRef(...refs) {
    return (thing) => {
      refs.forEach((ref) => (ref.current = thing));
    };
  }

  useEffect(() => {
    if (document && window && showCart.showId) {
      setTimeout(() => {
        const scrollElement = document.getElementById(
          `cart-${showCart.showId}`
        );
        const topDistance = scrollElement?.offsetTop;
        containerRef?.current?.scrollTo(0, topDistance - 10);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCart.showId]);

  return (
    <>
      <ToolTip
        active={tipActive}
        title={tipInfo.title}
        description={tipInfo.description}
        setTipActive={setTipActive}
      />
      <div className="w-full">
        <div
          className={`${
            showCart.value ? "" : "hidden"
          } w-screen h-screen transform-gpu transition-all duration-500 opacity-30 bg-black fixed inset-0 z-20`}
        ></div>
        <div
          ref={shareRef(containerRef)}
          className={classNames(
            "fixed bottom-0 right-0 top-14 lg:top-12 pb-5 w-full md:w-vw-5/12 xl:w-vw-1/4 bg-white z-30 text-theme-blue transform-gpu overflow-hidden transition-all duration-300 overflow-y-scroll",
            !showCart.value ? "translate-x-full" : "translate-x-0"
          )}
        >
          <div
            className={classNames(
              "flex justify-end w-full",
              showToastMessage && dialogType.header
                ? dialogType.holidayHeader
                  ? "mt-16"
                  : "mt-12 sm:mt-12"
                : ""
            )}
          >
            <button
              className={classNames(
                "h-8 w-8 mx-2 absolute ",
                scrollHeight < 60 && "lg:mt-20",
                !isEmpty ? "my-5" : "my-3"
              )}
              onClick={() => setShowCart({ value: false, showId: "" })}
            >
              <CloseIcon />
            </button>
          </div>
          {isEmpty ? (
            <CartEmptyState scrollHeight={scrollHeight} />
          ) : (
            <>
              <CartHeading
                isTryHomeIncomplete={isTryHomeIncomplete}
                onBack={() => {
                  setShowCart({ value: false, showId: "" });
                }}
                scrollHeight={scrollHeight}
              />

              {studItems.length > 0
                ? studItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}`}
                        studDetail={item}
                        hasDiamond={true}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {braceletItems.length > 0
                ? braceletItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}`}
                        braceletDetail={item}
                        hasDiamond={true}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {giftCardItems.length > 0
                ? giftCardItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}`}
                        giftCardDetail={item}
                        hasDiamond={true}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {ringWithDiamond.length > 0
                ? ringWithDiamond.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}`}
                        diamondDetail={item}
                        ringDetail={item}
                        hasDiamond={true}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {necklaceWithDiamond.length > 0
                ? necklaceWithDiamond.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}`}
                        diamondDetail={item}
                        ringDetail={item}
                        hasDiamond={true}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {ringItems.length > 0
                ? ringItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}-ring`}
                        ringDetail={item}
                        type={"ring"}
                        needDiamond={
                          item.ring_detail.tags.custom_diamond === "TRUE"
                        }
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {necklaceItems.length > 0
                ? necklaceItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}-ring`}
                        ringDetail={item}
                        type={"ring"}
                        needDiamond={
                          item.necklace_detail.tags.custom_diamond === "TRUE"
                        }
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {diamondItems.length > 0
                ? diamondItems.map((item, idx) => {
                    return (
                      <CartMainItem
                        key={`${item.id}-${idx}-diamond`}
                        diamondDetail={item}
                        type={"diamond"}
                        hasDiamond={
                          item.diamond_detail.Type === "Moissanite"
                            ? true
                            : false
                        }
                        needRing={item.preference === "primary"}
                        {...{
                          onRemove,
                          haveSoldDiamonds,
                        }}
                        primaryType={item.preference}
                        setShowCart={setShowCart}
                      />
                    );
                  })
                : null}
              {replicaItems.length > 0 ? (
                <TryAtHome {...{ maxReplicaItems, replicaItems, onRemove }} />
              ) : null}

              {haveSoldDiamonds.status ? (
                <div className="flex px-5 pt-4 justify-between text-md 4xl:text-2xl leading-snug 4xl:leading-tight">
                  <p>
                    Your selected diamond is sold out. Please select another
                    one.{" "}
                    <Link legacyBehavior href={similarDiamondLink}>
                      <a
                        onClick={() =>
                          setShowCart({ value: false, showId: "" })
                        }
                        className="underline"
                      >
                        View Similar Diamonds
                      </a>
                    </Link>
                  </p>
                </div>
              ) : (
                <div className="flex px-5 pt-4 justify-between text-lg 4xl:text-2xl leading-snug 4xl:leading-tight">
                  <p className="uppercase font-serif">Cart Total</p>
                  <p id="cart-total-price">{formatPrice(Number(totalPrice))}</p>
                </div>
              )}
              <div className="px-5 pt-4">
                <p
                  className="affirm-as-low-as"
                  data-page-type="product"
                  data-amount={`${Math.ceil(totalPrice) * 100}`}
                ></p>
              </div>
              <div className="px-5">
                <Button
                  id="checkout-button"
                  variant="primary"
                  className="h-16 my-5"
                  onClick={onCheckOut}
                  isDisabled={isItemIncomplete || isTryHomeIncomplete}
                  loading={checkOutLoading}
                >
                  Checkout
                </Button>
                <div className="flex flex-col space-y-3">
                  <p className="text-lg 4xl:text-2xl leading-snug 4xl:leading-tight align-middle">
                    Free Express Shipping
                    <br />
                    <Link
                      legacyBehavior
                      href={`/contact?scroll_target=faq&${queryString.stringify(
                        {
                          ...(dialogType.bottom &&
                          router.query &&
                          router.query.ranMID
                            ? getRakutenParams
                            : {}),
                        }
                      )}`}
                    >
                      <a className="underline">Free 30 Day Returns</a>
                    </Link>
                    <br />
                    <Link
                      legacyBehavior
                      href={`/contact?scroll_target=faq&${queryString.stringify(
                        {
                          ...(dialogType.bottom &&
                          router.query &&
                          router.query.ranMID
                            ? getRakutenParams
                            : {}),
                        }
                      )}`}
                    >
                      <a className="underline">Lifetime Warranty</a>
                    </Link>
                    <br />
                    Free Lifetime Care
                    <br />
                    <span className="flex">
                      {isItemIncomplete || isTryHomeIncomplete
                        ? ""
                        : hasRingSize
                        ? `Ship Date TBD`
                        : shippingDate
                        ? `Estimated to Ship by
                    ${
                      monthsInLong[shippingDate?.getMonth()]
                    } ${shippingDate?.getDate()}${getOrdinal(
                            shippingDate?.getDate()
                          )}`
                        : ""}

                      {hasRingSize &&
                      !(isItemIncomplete || isTryHomeIncomplete) ? (
                        <button
                          type="button"
                          className="w-3 h-3 inline-block ml-1"
                          onClick={() => {
                            setTipInfo({
                              title: "CHOOSING A SIZE",
                              description:
                                "Orders placed without a ring size will receive a free ring sizer via express shipping before we make your ring. Your ship date will be determined once you provide your ring size.",
                            });
                            setTipActive(true);
                          }}
                        >
                          <TipIcon className="h-3 " color="#1c1794" />
                        </button>
                      ) : null}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

Cart.propTypes = {
  ringItems: PropTypes.arrayOf(PropTypes.object),
  diamondItems: PropTypes.arrayOf(PropTypes.object),
  replicaItems: PropTypes.arrayOf(PropTypes.object),
  ringWithDiamond: PropTypes.arrayOf(PropTypes.object),
  onRemove: PropTypes.func,
  onCheckOut: PropTypes.func,
  isEmpty: PropTypes.bool,
  maxReplicaItems: PropTypes.string,
  totalPrice: PropTypes.number,
  isItemIncomplete: PropTypes.bool,
  isTryHomeIncomplete: PropTypes.bool,
  checkOutLoading: PropTypes.bool,
  showToastMessage: PropTypes.bool,
  hasRingSize: PropTypes.bool,
};

export default Cart;
