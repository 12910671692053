/* eslint-disable valid-jsdoc */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Loader from "../../components/Loader";
import Link from "next/link";

const LearnMoreModal = ({
  open,
  handleClose,
  noBackground,
  content,
  ...rest
}) => {
  const suppilerStages = [
    {
      name: "Level One",
      natural_diamonds: "Conflict-Free, Ethically Sourced",
      lab_diamond: "Lab Grown, Certified, Ethically Sourced",
    },
    {
      name: "Level Two",
      natural_diamonds: "Certified",
      lab_diamond: "Disclosed Origin",
    },
    {
      name: "Level Three",
      natural_diamonds: "Disclosed Origin",
      lab_diamond: "Grown in USA",
    },
    {
      name: "Level Four",
      natural_diamonds: "GIA Certified Origin and/or Recycled",
      lab_diamond: "Carbon Neutral",
    },
  ];

  /**
   * Learn more popup content rendered based on the the type of the details
   * @param {*} param0
   * @returns
   */
  const renderContent = ({ type }) => {
    switch (type) {
      case "supplier":
        return (
          <div className="p-4 w-full">
            <div className="w-full flex flex-col items-center">
              {/* title */}
              <div className="text-[32px] md:text-[60px] text-center font-serif py-8 uppercase">
                Supplier Standards
              </div>
              {/* suppierDescription */}
              <div className="pb-8 text-xs md:text-lg text-center max-w-[420px] md:leading-6">
                {content.description}
              </div>
              {/* table grid */}
              <div className="max-w-[460px] w-full grid grid-cols-3 mb-8 border-theme-blue border-[0.5px]">
                <div className="p-1 border-theme-blue border-[0.5px]"></div>
                <div className="font-semibold text-sm p-1 border-theme-blue border-[0.5px] text-center justify-center flex items-center">
                  Natural Diamonds
                </div>
                <div className="font-semibold text-sm p-1 border-theme-blue border-[0.5px] text-center justify-center flex items-center">
                  Lab Diamonds
                </div>
                {suppilerStages?.map((el, index) => (
                  <Fragment key={index}>
                    <div className="font-semibold text-sm p-1 border-theme-blue border-[0.5px] justify-center flex items-center">
                      {el?.name}
                    </div>
                    <div className="text-xs p-1 border-theme-blue border-[0.5px] text-center justify-center flex items-center">
                      {el?.natural_diamonds}
                    </div>
                    <div className="text-xs p-1 border-theme-blue border-[0.5px] text-center justify-center flex items-center">
                      {el?.lab_diamond}
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      case "certificate":
        return (
          <div className="w-full" style={{ height: "calc(100vh - 100px)" }}>
            <div className="h-full w-full flex justify-center items-center">
              <Loader />
            </div>
            <iframe
              src={`${content?.url}#toolbar=0`}
              title="pdf viewer"
              allowFullScree={true}
              className="absolute inset-0 h-full w-full p-10"
            />
          </div>
        );
      case "sold-diamonds":
        return (
          <div className="p-4 w-full">
            <div className="flex flex-col items-center">
              <div className="text-[32px] md:text-[42px] text-center font-serif pt-6 mb-2">
                The selected diamond is sold out
              </div>

              <div className="bg-theme-blue text-white flex items-center justify-center mt-2 md:mb-4">
                <Link legacyBehavior href={rest?.link}>
                  <a
                    onClick={handleClose}
                    className="flex py-4 px-8 font-sans justify-center items-center uppercase tracking-widest"
                  >
                    View Similar Diamonds
                    <svg
                      viewBox="0 0 1792 1792"
                      className="w-4 h-4 overflow-hidden arrow-animate mb-[3px]"
                    >
                      <path
                        fill="currentColor"
                        d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"
                      />
                    </svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="p-4 w-full">
            <div className="flex flex-col items-center">
              {/* image container */}
              <div className="text-[32px] md:text-[60px] text-center font-serif pt-8 uppercase">
                {content?.type}
              </div>
              {content?.image && (
                <div className="sm:pb-6 w-full">
                  <img
                    className="w-full"
                    src={content?.image}
                    alt={"learn-more-modal-image"}
                  />
                </div>
              )}
              <div className="pb-8 text-lg text-center max-w-[420px] leading-[22px]">
                {content.description}
              </div>
            </div>
          </div>
        );
    }
  };

  if (!open) return null;

  return (
    <div
      {...rest}
      className="fixed inset-0 flex items-center justify-center px-4 pb-4 bg-black bg-opacity-50 z-50 text-theme-blue"
    >
      <div
        className={`${
          !noBackground ? "bg-white  max-w-2xl" : "bg-transparent  max-w-4xl"
        } overflow-auto relative w-full animate-popdown`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style={{ maxHeight: "calc(100% - 50px)" }}
      >
        <button
          className={`absolute z-50 right-3 top-3 ${
            !noBackground ? "w-10 h-10" : "w-8 h-8"
          } rounded-full flex justify-center items-center cursor-pointer text-current`}
          title="Close"
          onClick={() => handleClose()}
        >
          <svg
            width={!noBackground ? "32" : "24"}
            height={!noBackground ? "32" : "24"}
            viewBox={!noBackground ? "0 0 28 28" : "0 0 20 20"}
          >
            <g
              stroke={!noBackground ? "#1c1794" : "#ffffff"}
              strokeWidth={!noBackground ? "1" : "2"}
              fill={!noBackground ? "#1c1794" : "#ffffff"}
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <path d="M5 15L15 5M5 5l10 10" />
            </g>
          </svg>
        </button>
        <div className="flex w-full flex-auto md:text-theme-blue h-full">
          {renderContent(content)}
        </div>
      </div>
    </div>
  );
};

LearnMoreModal.propTypes = {
  /**
   * If modal is open
   */
  open: PropTypes.bool,
  /**
   * Function to close modal
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Title of the modal
   */
  title: PropTypes.string,
  /**
   * required bg or not
   */
  noBackground: PropTypes.any,

  /**
   * content Modal content object
   */
  content: PropTypes.any,
};

export default LearnMoreModal;
