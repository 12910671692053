import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import AnimatedLink from "./AnimatedLink";
import { MissingDiamond } from "../_helpers/Icons/miscellaneousIcons";
import { Teardropring } from "../_helpers/Icons/headerstepsIcons";

const CartMissingItem = ({
  ringShape,
  diamondSetting,
  missingDiamond,
  productId,
  ringSize,
  engraving,
  isNecklace,
}) => {
  return (
    <div className="mt-8 relative font-sans text-theme-blue flex">
      <div className="w-1/2 relative mr-2 flex justify-center items-center">
        {missingDiamond ? (
          <MissingDiamond
            className="text-theme-blue fill-current w-20 mx-auto"
            isDiamondChecked={true}
          />
        ) : (
          <Teardropring className="text-theme-blue fill-current w-20 mx-auto" />
        )}
      </div>
      <div className="w-2/3">
        <p
          className={`${
            missingDiamond ? "mb-4" : ""
          } text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight`}
        >
          {missingDiamond
            ? `This ${isNecklace ? "necklace" : "ring"} is missing a stone`
            : "This diamond needs a setting"}
          .
          <br />
          {!missingDiamond
            ? "Select one to checkout."
            : "Please select a diamond to checkout."}
        </p>

        <div className="w-full relative flex items-center justify-between">
          <AnimatedLink
            cta={`SELECT ${missingDiamond ? "STONE" : "SETTING"}`}
            color="theme-blue"
            href={missingDiamond ? `/diamonds` : `/ring-style`}
            query={queryString.stringify(
              missingDiamond
                ? {
                    shape: diamondSetting,
                    [isNecklace ? "necklace_id" : "ring_id"]: productId,
                    ...(isNecklace
                      ? {}
                      : { ring_size: ringSize, engrave: engraving }),
                    show_grid: true,
                  }
                : {
                    shape: ringShape,
                    diamond_id: productId,
                  }
            )}
          />
        </div>
      </div>
    </div>
  );
};

CartMissingItem.propTypes = {
  ringShape: PropTypes.string,
  diamondSetting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  missingDiamond: PropTypes.bool,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ringSize: PropTypes.number,
  engraving: PropTypes.string,
};

export default CartMissingItem;
