/* eslint-disable no-prototype-builtins */
/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import Head from "next/head";
import queryString from "query-string";
import { useRouter } from "next/router";
import { Carousel } from "react-responsive-carousel";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import indexOf from "lodash/indexOf";
import find from "lodash/find";
import uniq from "lodash/uniq";
import dynamic from "next/dynamic";

const LottieHeader = dynamic(() => import("lottie-react"), {
  ssr: false,
});

import { useCart } from "../services/cart";
import useDialog from "../hooks/useDialog";
import { getCloudFrontUrl, getRakutenParams } from "../_helpers/functions";
import ShareDropDown from "./ShareDropDown";
import useLocalePrice from "../hooks/useLocalePrice";
import animation from "../../src/components/RingProducts/GiftingTag.json";
import { Heart, WishlistHeart } from "../_helpers/Icons/headerIcons";
import ProductIcons from "../_helpers/Icons/productIcons";
import classNames from "classnames";

const metalOrder = ["YG", "WG", "RG", "PL"];

const ProductCard = ({
  id,
  handle,
  title,
  metal,
  small_description,
  variant_details,
  replica_metal,
  className,
  isAddedToCart,
  isFaved,
  onAddToCart,
  onAddToFaved,
  defaultMetal,
  showSmallDescription,
  tags,
  previousData,
  shape,
  hoverMetafieldData,
  productType,
  ringDetails,
  checkReplica,
  baseUrl,
  shareOption,
  setShareOption,
  fromTryAtHome,
  isSearch = false,
  isFromRecent = false,
}) => {
  const replaceUrl = "https://cdn.shopify.com";
  const router = useRouter();
  const { dialogType } = useDialog();
  const { preference } = useCart();
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const { formatPrice } = useLocalePrice();
  const [selectedVariant, setSelectedVariant] = useState(
    indexOf(metal, defaultMetal) > -1 && !variant_details[defaultMetal]?.hidden
      ? defaultMetal
      : find(metal, (el) => !variant_details[el]?.hidden)
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCartToolTip, setShowCartToolTip] = useState(false);
  // For gifting guide animation
  const [isForGifting, setIsForGifting] = useState(false);
  const closeRef = useRef(null);

  const canShowAddToCart = () => {
    const replica = replica_metal?.[selectedVariant];
    if (!replica) return false;
    if (replica.inventory_quantity > 0) return true;
    return false;
  };
  const variant = selectedVariant === "PL" ? "WG" : selectedVariant;
  const hoverImg = !isEmpty(hoverMetafieldData?.[variant])
    ? hoverMetafieldData[variant]
    : variant_details?.[selectedVariant]?.image;

  const href = () => {
    const {
      shape,
      style,
      collection,
      metal,
      profile,
      stackable,
      category,
      bandwidth,
      try_at_home,
    } = router.query;
    const isRouterFilterAvailable =
      (shape ||
        style ||
        collection ||
        metal ||
        profile ||
        stackable ||
        category ||
        bandwidth ||
        try_at_home) &&
      // adding isSearch as a seperate prop because we cannot determine whether search page is accessed or not -- not a route, just a popup
      !(preference?.id || previousData?.id || isSearch);
    switch (productType) {
      // support shopify configuration
      case "Pendant Necklace":
      case "necklace":
        return `/necklaces/diamond-pendant-necklace/${handle}?${
          isRouterFilterAvailable
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}${
          preference && preference.type === "diamond"
            ? `&diamond_id=${preference.id}${
                isEmpty(previousData) ? `&shape=${shape}` : ""
              }`
            : ""
        }${
          !isEmpty(previousData)
            ? `&id=${previousData.id}&ring_size=${previousData.ring_size}&engrave=${previousData.engrave}&mode=change&shape=${shape}&variant_id=${previousData.variant_id}`
            : ""
        }&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;

      // support shopify configuration
      case "Stud Earrings":
      case "stud":
        return `/earrings/diamond-stud-earrings/${handle}?${
          isRouterFilterAvailable
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}${
          !isEmpty(previousData)
            ? `&id=${previousData.id}&mode=change&variant_id=${previousData.variant_id}`
            : ""
        }&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;

      // support shopify configuration
      case "Wedding Ring":
      case "wedding":
        return `/wedding-rings/${handle}?${
          isRouterFilterAvailable
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;

      // support shopify configuration
      case "Engagement Ring":
      case "engagement":
        return `/engagement-rings/${handle}?${
          isRouterFilterAvailable && !isFromRecent
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}${
          preference && preference.type === "diamond"
            ? `&diamond_id=${preference.id}${
                isEmpty(previousData) ? `&shape=${shape}` : ""
              }`
            : fromTryAtHome
            ? `&fromTryAtHome=${fromTryAtHome}`
            : ""
        }${
          !isEmpty(previousData)
            ? `&id=${previousData.id}&ring_size=${previousData.ring_size}&engrave=${previousData.engrave}&mode=change&shape=${shape}&variant_id=${previousData.variant_id}`
            : ""
        }&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;

      // support shopify configuration
      case "Tennis Bracelet":
      case "bracelet":
        return `/bracelets/tennis-bracelets/${handle}?${
          isRouterFilterAvailable
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}${
          !isEmpty(previousData)
            ? `&id=${previousData.id}&mode=change&variant_id=${previousData.variant_id}`
            : ""
        }&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;

      default:
        return `/engagement-rings/${handle}?${
          isRouterFilterAvailable && !isFromRecent
            ? `mode=filters&${queryString.stringify(router.query)}`
            : ""
        }&colorkey=${selectedVariant}${
          preference && preference.type === "diamond"
            ? `&diamond_id=${preference.id}${
                isEmpty(previousData) ? `&shape=${shape}` : ""
              }`
            : ""
        }${
          !isEmpty(previousData)
            ? `&id=${previousData.id}&ring_size=${previousData.ring_size}&engrave=${previousData.engrave}&mode=change&shape=${shape}&variant_id=${previousData.variant_id}`
            : ""
        }&${queryString.stringify({
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        })}`;
    }
  };

  useEffect(() => {
    if (id != shareOption) {
      setShowShareDropdown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareOption]);

  useEffect(() => {
    setSelectedVariant(defaultMetal);
  }, [defaultMetal]);

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (closeRef.current && !closeRef.current.contains(event.target)) {
        setShowShareDropdown(false);
      }
    });
  }, [closeRef]);

  useLayoutEffect(() => {
    setIsForGifting(tags?.gifting_tag?.toLowerCase() === "true");
  }, [tags]);

  const getAllImages = () => {
    const imgs = [];
    // eslint-disable-next-line guard-for-in
    for (const key in variant_details) {
      const defaultImg = variant_details[key]?.image?.src;
      const hoverImg = hoverMetafieldData?.[key]?.src;
      if (defaultImg)
        imgs.push(getCloudFrontUrl(defaultImg, replaceUrl, true)?.src);
      if (hoverImg)
        imgs.push(getCloudFrontUrl(hoverImg, replaceUrl, true)?.src);
    }
    return compact(uniq(imgs));
  };

  const carouselData = () => {
    const variantImages = [
      { ...variant_details[selectedVariant]?.image, itemNumber: 1 },
      { ...hoverImg, itemNumber: 2 },
    ];
    return variantImages.map((item) => (
      <div
        className="overflow-hidden"
        id={`${id}-${selectedVariant}-${item?.itemNumber}`}
        key={`${id}-${selectedVariant}-${item?.itemNumber}`}
      >
        <img
          loading="lazy"
          id={`${id}-${selectedVariant}-${item?.itemNumber}`}
          className="w-100 h-100 px-3 sm:px-0 scale-125 sm:scale-100"
          {...getCloudFrontUrl(item?.src, replaceUrl, true)}
          alt={item?.alt}
        />
      </div>
    ));
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [selectedVariant]);

  const customIndicator = (onClickHandler, isSelected, index) => {
    if (isSelected) {
      return (
        <li
          className={classNames(
            "inline-block h-1 w-1 sm:h-2.5 sm:w-2.5 rounded-full bg-theme-blue mx-1.5",
            productType === "necklace" ? "mb-9 sm:mb-8" : "mb-14"
          )}
          aria-label={`Selected: ${index + 1}`}
        />
      );
    }
    return (
      <li
        className={classNames(
          "inline-block h-1 w-1 sm:h-2.5 sm:w-2.5 rounded-full bg-white border border-theme-blue mx-1.5",
          productType === "necklace" ? "mb-9 sm:mb-8" : "mb-14"
        )}
        onClick={(e) => {
          e.preventDefault();
          onClickHandler();
        }}
        onKeyDown={(e) => {
          e.preventDefault();
          onClickHandler();
        }}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${index + 1}`}
      />
    );
  };

  const getProductUrl = () => {
    switch (productType) {
      case "wedding":
      case "Wedding Ring":
        return "wedding-rings";
      case "necklace":
      case "Pendant Necklace":
        return "necklaces/diamond-pendant-necklace";
      case "stud":
      case "Stud Earrings":
        return "earrings/diamond-stud-earrings";
      case "bracelet":
      case "Tennis Bracelet":
        return "bracelets/tennis-bracelets";
      default:
        return "engagement-rings";
    }
  };

  return (
    <div
      className={`bg-white border-theme-blue relative group overflow-hidden text-theme-blue ${className}`}
      id={id}
      ref={closeRef}
      onMouseLeave={() => setShowShareDropdown(false)}
    >
      <Head>
        {getAllImages().map((img) => (
          <link key={img} rel="preload" href={img} as="image" />
        ))}
      </Head>
      <h5
        className={`absolute z-10 h-fit bottom-5 w-full md:w-2/4 px-3 md:px-0 md:left-8 md:top-5`}
      >
        <span className="flex flex-wrap font-serif uppercase text-sm sm:text-base md:text-xl 3xl:text-2xl 4xl:text-3xl mb-[10px] md:mb-0">
          <Link legacyBehavior href={href()}>
            <a>{title?.split("|")[0]}</a>
          </Link>
        </span>
      </h5>
      <ul className="w-full h-full bg-gradient-to-r from-[#eceeea] to-[#f1f3ec]">
        {metalOrder.map((metal, index) => {
          if (!variant_details?.hasOwnProperty(metal)) return null;
          return (
            <li
              key={`${metal}-${index}`}
              className={`absolute transition-opacity duration-200 w-full h-full ${
                metal == selectedVariant ? "opacity-100" : "opacity-0"
              }`}
            >
              <Link legacyBehavior href={href()}>
                <a>
                  <div className="relative hidden md:flex">
                    <img
                      loading="lazy"
                      className="w-100 h-100"
                      {...getCloudFrontUrl(
                        variant_details[selectedVariant]?.image?.src,
                        replaceUrl,
                        true
                      )}
                      alt={variant_details[selectedVariant]?.image?.alt}
                    />
                    <div className="absolute top-0 left-0 h-full w-full opacity-0 group-hover:opacity-100 transition-all duration-200">
                      <img
                        loading="lazy"
                        className="w-100 h-100"
                        src={hoverImg?.src}
                        {...getCloudFrontUrl(hoverImg?.src, replaceUrl, true)}
                        alt={hoverImg?.alt}
                      />
                    </div>
                  </div>
                  <div className="flex md:hidden h-full">
                    <Carousel
                      className="flex items-stretch product-card"
                      showArrows={false}
                      showStatus={false}
                      showThumbs={false}
                      transitionTime={500}
                      showIndicators={true}
                      onChange={(currIndex) => setCurrentIndex(currIndex)}
                      selectedItem={currentIndex}
                      renderIndicator={customIndicator}
                      autoPlay={false}
                      preventMovementUntilSwipeScrollTolerance={true}
                    >
                      {carouselData()}
                    </Carousel>
                  </div>
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
      <nav className="absolute top-3 w-full flex flex-row-reverse md:top-0">
        {/* Gifting guide animation */}
        {isForGifting && (
          <LottieHeader
            animationData={animation}
            className="h-5 md:h-[30px] lg:h-[38px] 2xl:h-[42px] absolute left-2 top-0 lg:block md:left-auto md:mt-14 mr-6 lg:mt-4 lg:mr-4 lg:group-hover:hidden transition-all"
          />
        )}
        <div
          className={`gap-2 md:gap-0 h-5 md:h-6 md:w-32 z-20 mr-2 md:mr-0 md:pt-5 md:pr-6 left-0 relative flex flex-row ${"justify-between"} lg:transform lg:opacity-0 lg:group-hover:opacity-100 lg:-translate-y-full lg:group-hover:translate-y-0 transition-all lg:duration-200`}
        >
          <button
            onClick={() => onAddToCart(selectedVariant)}
            className={`text-theme-blue block focus:outline-none ${
              canShowAddToCart()
                ? "opacity-100"
                : "opacity-0 pointer-events-none"
            }`}
            onMouseLeave={() => {
              setShowCartToolTip(false), setShowShareDropdown(false);
            }}
            onMouseEnter={() => {
              setShowCartToolTip(true);
            }}
          >
            {isAddedToCart(replica_metal?.[selectedVariant]) ? (
              <ProductIcons.PlusFill className="h-4 w-4 md:h-6 md:w-6 4xl:h-10 4xl:w-10 text-theme-blue" />
            ) : (
              <ProductIcons.Plus className="h-4 w-4 md:h-6 md:w-6 4xl:h-10 4xl:w-10 text-theme-blue" />
            )}
            <div
              className={`right-1 top-8 md:top-14 z-10 ${
                isAddedToCart(replica_metal?.[selectedVariant])
                  ? "w-36 sm:w-40 bg-theme-blue text-white before:mr-8 after:mr-8 md:before:mr-[90px] md:after:mr-[90px] before:border-transparentBlue after:border-transparentBlue"
                  : "w-32 sm:w-36 bg-white text-theme-blue before:mr-8 after:mr-8 md:before:mr-[90px] md:after:mr-[90px] before:border-transparentBlue after:border-transparentWhite"
              } ring-tooltip absolute border border-theme-blue flex justify-center ${
                showCartToolTip
                  ? "opacity-100 translate-y-0 h-auto py-0.5"
                  : "opacity-0 -translate-y-full h-0 p-0 m-0 hidden"
              }`}
            >
              <p className="text-xs sm:text-sm leading-snug text-center z-50">
                {isAddedToCart(replica_metal?.[selectedVariant])
                  ? "Added to Home Try On"
                  : "Add to Home Try On"}
              </p>
            </div>
          </button>

          <button
            onClick={onAddToFaved}
            className="text-theme-blue block focus:outline-none"
            onMouseEnter={() => setShowShareDropdown(false)}
          >
            {isFaved ? (
              <WishlistHeart className="h-4 w-4 md:h-6 md:w-6 4xl:h-10 4xl:w-10 text-theme-blue" />
            ) : (
              <Heart className="h-4 w-4 md:h-6 md:w-6 4xl:h-10 4xl:w-10 text-theme-blue" />
            )}
          </button>
          <button
            onClick={() => {
              setShowShareDropdown(!showShareDropdown);
              setShareOption(id);
            }}
            onMouseEnter={() => setShowShareDropdown(true)}
            className="text-theme-blue block focus:outline-none"
          >
            <ProductIcons.ShareArrow className="h-4 w-4 md:h-6 md:w-6 4xl:h-10 4xl:w-10 text-theme-blue" />
          </button>
        </div>
        <ShareDropDown
          open={showShareDropdown}
          setShowDropDown={() => setShowShareDropdown(false)}
          url={`https://${baseUrl}/${getProductUrl()}/${handle}`}
        />
      </nav>
      <h4
        className="text-sm sm:text-lg absolute left-3 bottom-2 sm:left-3 sm:bottom-2 md:bottom-4 md:left-8 lg:transition-opacity lg:duration-200 lg:opacity-0 lg:group-hover:opacity-100 text-left text-theme-blue"
        id={`${id}-price`}
      >
        {formatPrice(
          Math.ceil(Number(variant_details?.[selectedVariant]?.price)),
          "{symbol}{price}",
          0
        )}
        {ringDetails?.custom_diamond === "true" ? "+" : null}
      </h4>
    </div>
  );
};

ProductCard.defaultProps = {
  className:
    "w-vw-1/2 h-vw-7/12 sm:h-vw-1/2 lg:w-vw-1/3 lg:h-vw-1/3  2xl:h-vw-1/4 2xl:w-vw-1/4 border-r border-b",
  defaultMetal: "YG",
  showSmallDescription: true,
  isSearch: false,
};

ProductCard.propTypes = {
  /**
   * ID of the section
   */
  id: PropTypes.string,
  /**
   * Center title of the section
   */
  title: PropTypes.string,
  /**
   * slug for redirection
   */
  handle: PropTypes.string,
  /**
   * small quick description
   */
  small_description: PropTypes.string,
  /**
   * price of product
   */
  className: PropTypes.string,
  /**
   * Whether product is added to cart already or not
   */
  isAddedToCart: PropTypes.func,
  /**
   * Whether product is faved already or not
   */
  isFaved: PropTypes.bool,
  /**
   * variant_details: list of colors
   */
  variant_details: PropTypes.object.isRequired,
  /**
   * Onclick function for cart
   */
  onAddToCart: PropTypes.func,
  /**
   * Onclick function for wishlist/fav
   */
  onAddToFaved: PropTypes.func,
  /**
   * Replica metals of product
   */
  replica_metal: PropTypes.object,
  /**
   * Ring ttags of product
   */
  tags: PropTypes.object,
  /**
   * Showing small desciption or not
   */
  showSmallDescription: PropTypes.bool,
  /**
   * Default metal type
   */
  defaultMetal: PropTypes.string,
  /**
   * Metals of the product item
   */
  metal: PropTypes.array,
  preference: PropTypes.string,
  shape: PropTypes.string,
  setPreference: PropTypes.func,
  previousData: PropTypes.shape({
    id: PropTypes.string,
    ring_size: PropTypes.string,
    engrave: PropTypes.string,
    variant_id: PropTypes.string,
  }),
  /**
   * Hover Metafields
   */
  hoverMetafieldData: PropTypes.object,
  /**
   * To determine if the product card is accessed from search related routes
   */
  isSearch: PropTypes.bool,
};

export default ProductCard;
