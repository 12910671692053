import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize(process.env.GA_ANALYTICS_ID);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logModalView = (from, path) => {
  ReactGA.modalview(`${from}/${path}`);
};

export const logEvent = (data) => {
  ReactGA.event({ ...data, nonInteraction: true });
};
