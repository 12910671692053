import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { CloseIcon } from "../_helpers/Icons/miscellaneousIcons";
import useLocalePrice from "../hooks/useLocalePrice";

const CartTryAtHome = ({ maxReplicaItems, replicaItems, onRemove }) => {
  const { formatPrice } = useLocalePrice();
  return (
    <div className="w-full">
      <div className="py-6 border-t border-theme-blue">
        <p className="text-center text-lg 3xl:text-2xl 4xl:text-3xl leading-snug 3xl:leading-tight font-serif uppercase">
          Try at home rings
        </p>
        <p className="text-center text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight font-sans">
          {replicaItems.length}{" "}
          {maxReplicaItems >= replicaItems.length
            ? `of ${maxReplicaItems}`
            : ""}{" "}
          rings choosen
        </p>
      </div>
      <ul className="list-none">
        {replicaItems.map((tryItems) => {
          return (
            <li
              key={tryItems.id}
              className="pt-6 pb-8 px-7 border-t border-b border-theme-blue relative"
            >
              <div className="flex justify-between items-center w-full text-lg 3xl:text-2xl 4xl:text-3xl leading-snug 3xl:leading-tight font-sans 3xl:mb-9">
                <div className="flex items-center">
                  <span
                    onClick={() => onRemove({ id: tryItems.id })}
                    className="mr-1.5 cursor-pointer"
                  >
                    <CloseIcon className="w-6 h-6" />
                  </span>
                  <p className="font-serif uppercase">
                    {tryItems.ring_detail.title}
                  </p>
                </div>
                <p>{formatPrice(Number(tryItems.replica_detail.price))}</p>
              </div>
              <div className="relative flex items-start mt-7">
                <div className="mr-2 w-1/2 relative overflow-hidden">
                  <Image
                    className="hover:scale-110 transform transition-all delay-150 ease-in"
                    // loader={() => tryItems.variant.image.src}
                    src={tryItems.variant.image.src}
                    alt={tryItems.variant.image.alt}
                    height={1}
                    width={1}
                    placeholder="blur"
                    blurDataURL={tryItems.variant.image.src}
                    layout="responsive"
                  />
                </div>

                <div className="pr-8 font-sans w-2/3 relative text-theme-blue 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight">
                  <p>Try On Kit</p>

                  <p>{tryItems.replica_detail.title}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CartTryAtHome.propTypes = {
  maxReplicaItems: PropTypes.string,
  replicaItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      variant: PropTypes.shape({
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
      }),
      ring_detail: PropTypes.shape({
        title: PropTypes.string,
      }),
      replica_detail: PropTypes.shape({
        title: PropTypes.string,
        price: PropTypes.string,
      }),
    })
  ),
  onRemove: PropTypes.func,
};

export default CartTryAtHome;
