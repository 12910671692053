import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { useRouter } from "next/router";
import useDialog from "../hooks/useDialog";
import isEmpty from "lodash/isEmpty";
import useLocalePrice from "../hooks/useLocalePrice";
import { getRakutenParams, isCustomDiamond } from "../_helpers/functions";

const CartDiamondSetting = ({
  image,
  name,
  cut,
  carat,
  color,
  clarity,
  settingPrice,
  isChangeble,
  onChangeDiamond,
  id,
  setShowCart,
  haveSoldDiamonds,
  diamondDetail,
  ...rest
}) => {
  const router = useRouter();
  const { dialogType } = useDialog();
  const { formatPrice } = useLocalePrice();
  const isCustomDiamondTrue = isCustomDiamond(id);

  return (
    <div className="mt-6" id={`cart-${id}`} {...rest}>
      {/* need to change here */}
      {isChangeble ? (
        <button
          onClick={() => onChangeDiamond()}
          className="text-theme-blue underline text-sm mb-2 font-medium"
        >
          Change Stone
        </button>
      ) : null}
      <div className="relative flex items-start">
        <div className="mr-2 w-1/2 relative overflow-hidden">
          <Image
            className="hover:scale-110 transform transition-all delay-150 ease-in bg-gray-100 object-contain"
            // loader={() => image}
            src={image}
            alt={name}
            height={1}
            width={1}
            placeholder="blur"
            blurDataURL={image}
            layout="responsive"
          />
        </div>

        <div className="font-sans w-2/3 relative text-theme-blue text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight">
          <div className="w-full flex justify-between mb-3">
            <p>Diamond:</p>
            <p>
              {haveSoldDiamonds.status && haveSoldDiamonds.ids.includes(id)
                ? "SOLD"
                : `${formatPrice(settingPrice)}`}
            </p>
          </div>

          <div className="w-full">
            <p>Shape: {name}</p>
            <p
              className={`${
                isCustomDiamondTrue ? "" : "underline cursor-pointer"
              } break-all w-40 md:w-32 3xl:w-44 4xl:w-60`}
              onClick={() => {
                if (!isCustomDiamondTrue) {
                  setShowCart({ value: false, showId: "" });
                  router.push(
                    {
                      pathname: `/diamonds/${id}`,
                      query: {
                        ...(dialogType.bottom &&
                        router.query &&
                        router.query.ranMID
                          ? getRakutenParams
                          : {}),
                      },
                    },
                    undefined,
                    { shallow: false }
                  );
                }
              }}
            >
              ID: {id}
            </p>
            <p>Cut: {cut}</p>
            <p>Clarity: {clarity}</p>
            <p>Carat: {carat}</p>
            <p>Color: {color}</p>
            {haveSoldDiamonds.status &&
            haveSoldDiamonds.ids.includes(id) &&
            !isEmpty(diamondDetail?.ring_detail) ? (
              <p
                onClick={() => onChangeDiamond(true)}
                className="cursor-pointer underline break-all w-40 md:w-32 3xl:w-44 4xl:w-60"
              >
                Edit
              </p>
            ) : (
              `${formatPrice(settingPrice)}`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CartDiamondSetting.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cut: PropTypes.string.isRequired,
  carat: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  clarity: PropTypes.string.isRequired,
  settingPrice: PropTypes.string.isRequired,
  isChangeble: PropTypes.bool,
  onChangeDiamond: PropTypes.func,
  setShowCart: PropTypes.func,
  haveSoldDiamonds: PropTypes.shape({
    status: PropTypes.bool,
    ids: PropTypes.array,
  }),
};

export default CartDiamondSetting;
