import { useContext } from "react";
import { LocalePriceContext } from "../providers/localePriceProvider";

// eslint-disable-next-line require-jsdoc
export default function useLocalePrice() {
  const value = useContext(LocalePriceContext);

  if (!value) {
    throw new Error("useLocalePrice must be used within a LocalePriceProvider");
  }

  return value;
}
