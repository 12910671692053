/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";
import ProductCard from "../../stories/ProductCard";
import { useRouter } from "next/router";
import { useCart } from "../../services/cart";
import useSnackbar from "../../hooks/useSnackbar";
import { useFave } from "../../services/fave";
import { getProductMetafieldData } from "../../_helpers/functions";
import filter from "lodash/filter";
import Button from "../../stories/Button";
import isEmpty from "lodash/isEmpty";
import { Cart } from "../../_helpers/Icons/headerIcons";

const SearchGrid = ({ data, baseUrl, query, onClose, SearchFooter }) => {
  const router = useRouter();
  const { cart, addToCart, isAddedToCart, removeFromCart, setShowCart } =
    useCart();
  const { snackbar } = useSnackbar();
  const { addToFave, isAddedToFave, removeFromFave } = useFave();
  const [shareOption, setShareOption] = useState("");

  const totalCount = data.length;

  const closeModalAndSearch = () => {
    router.push(`/search/?query=${query}`);
    if (router.pathname === "/search") {
      onClose();
    }
  };

  return (
    <>
      {totalCount > 0 && (
        <div className="flex justify-between items-center m-5 md:m-10 text-sm">
          <p className="text-white font-normal leading-4 uppercase">
            {totalCount} results
          </p>
          {totalCount >= 16 && (
            <div
              onClick={() => closeModalAndSearch()}
              className="text-white uppercase flex items-center cursor-pointer"
            >
              <span className="border-b">SEE ALL</span>
              <img
                src="/assets/images/newsletter/arrow-white.svg"
                alt="Arrow"
                className="h-6 ml-2"
              />
            </div>
          )}
        </div>
      )}

      <div className="flex flex-col z-0 mx-5 md:mx-10 overflow-y-scroll hide-scrollbar">
        <div className="flex flex-wrap">
          {data?.map((product, id) => {
            if (
              // use replica image only if it's available
              product.variant_details[undefined] &&
              isEmpty(product.variant_details[undefined]?.image)
            ) {
              return null;
            }
            const metaField = getProductMetafieldData(product, product?.images);

            return (
              <ProductCard
                {...product}
                shareOption={shareOption}
                setShareOption={setShareOption}
                baseUrl={baseUrl}
                key={product.title}
                isAddedToCart={(p) =>
                  isAddedToCart && isAddedToCart({ id: p?.id })
                }
                onAddToCart={(selectedVariant) => {
                  if (!product.replica_metal[selectedVariant]) return;
                  const data = {
                    type: "replica",
                    ring_detail: {
                      title: product.title,
                    },
                    variant: product.variant_details[selectedVariant],
                    replica_detail: product.replica_metal[selectedVariant],
                    id: product.replica_metal[selectedVariant].id,
                  };
                  if (
                    !isAddedToCart({
                      id: product.replica_metal[selectedVariant].id,
                    })
                  ) {
                    const previousReplicas = filter(cart.products, {
                      type: "replica",
                    });
                    if (previousReplicas.length < 3) {
                      addToCart(data);
                      setShowCart({ value: true, showId: "" });
                    } else {
                      snackbar({
                        message:
                          "You already have 3 Try at home rings in your cart. Remove one of them to add this to your cart.",
                        variant: "error",
                        dismissTime: 4000,
                        icon: <Cart className="w-full h-full text-white" />,
                      });
                    }
                  } else {
                    removeFromCart({
                      id: product.replica_metal[selectedVariant].id,
                    });
                  }
                }}
                onAddToFaved={() => {
                  if (isAddedToFave({ ringId: product.id })) {
                    removeFromFave({ ringId: product.id });
                  } else {
                    addToFave({ product, type: "ring" });
                  }
                }}
                isFaved={isAddedToFave && isAddedToFave({ ringId: product.id })}
                hoverMetafieldData={metaField?.hoverImages}
                defaultMetal={metaField?.defaultMetal}
                ringDetails={metaField?.ringDetails}
                productType={product.product_type}
                // a lot of arbitrary values to support the image css already written in product card
                className="lg:w-[calc(33.33vw-28px)] lg:h-[calc(33.33vw-28px)] w-[calc(100vw-38px)] h-[calc(100vw-38px)] md:w-[calc(50vw-40px)] md:h-[calc(50vw-40px)] 2xl:h-[calc(25vw-20px)] 2xl:w-[calc(25vw-20px)] md:border-r border-b"
                isSearch
              />
            );
          })}
        </div>
        {totalCount >= 16 ? (
          <div className="flex justify-center mt-10">
            <Button
              type="custom"
              className="w-80 h-16 bg-transparent hover:text-theme-blue uppercase"
              onClick={() => closeModalAndSearch()}
            >
              SEE ALL
            </Button>
          </div>
        ) : (
          <SearchFooter />
        )}
      </div>
    </>
  );
};

export default SearchGrid;
