import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import _ from "lodash";
import map from "lodash/map";
import lowerCase from "lodash/lowerCase";
import groupBy from "lodash/groupBy";
import Cart from "../../stories/Cart";
import { useCart } from "../../services/cart";
import API from "../../services/FrontEndAPI";
import {
  defaultShipDays,
  diamondShipDays,
  getRakutenParams,
  getUtmParams,
  isROWUser,
  quickShipDays,
  selectDiamondOverlayImage,
  utmParamString,
} from "../../_helpers/functions";
import { addBusinessDays, convertTZ } from "../../_helpers/functions";
import LearnMoreModal from "../../stories/LearnMoreModal";
import ModalContainer from "../ModalContainer/ModalContainer";
import { useRouter } from "next/router";
import useDialog from "../../hooks/useDialog";
import queryString from "querystring";
import { isCustomDiamond } from "../../_helpers/functions";

const CartWrapper = ({ showToastMessage }) => {
  const { cart, removeFromCart } = useCart();
  const [replicaItems, setReplicaItems] = useState([]);
  const [ringItems, setRingItems] = useState([]);
  const [necklaceItems, setNecklaceItems] = useState([]);
  const [studItems, setStudItems] = useState([]);
  const [braceletItems, setBraceletItems] = useState([]);
  const [giftCardItems, setGiftCardItems] = useState([]);
  const [diamondItems, setDiamondItems] = useState([]);
  const [ringWithDiamond, setRingWithDiamond] = useState([]);
  const [necklaceWithDiamond, setNecklaceWithDiamond] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [incompleteItems, setIncompleteItems] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [hasRingSize, setHasRingSize] = useState(false);
  const [shippingDate, setShippingDate] = useState("");
  const [weeks, setWeeks] = useState(3);
  const [haveSoldDiamonds, setHaveSoldDiamonds] = useState({
    status: false,
    ids: [],
  });
  const router = useRouter();
  const [content, setContent] = useState({});
  const { dialogType } = useDialog();

  useEffect(() => {
    // variables before update
    const preStudItems = filter(cart.products, { type: "stud" });
    const preReplicaItems = filter(cart.products, { type: "replica" });
    const preRingItems = filter(
      cart.products,
      (el) => el.type === "ring" && !el.diamond_id
    );
    const preDiamondItems = filter(
      cart.products,
      (el) => el.type === "diamond" && !el.ring_id && !el.necklace_id
    );
    const preNecklaceItems = filter(
      cart.products,
      (el) => el.type === "necklace" && !el.diamond_id
    );

    const preBraceletItems = filter(cart.products, { type: "bracelet" });
    const preGiftCardItems = filter(cart.products, { type: "misc" });

    setReplicaItems(preReplicaItems);
    setRingItems(preRingItems);
    setNecklaceItems(preNecklaceItems);
    setDiamondItems(preDiamondItems);
    setStudItems(preStudItems);
    setBraceletItems(preBraceletItems);
    setGiftCardItems(preGiftCardItems);

    setHasRingSize(
      !!filter(
        cart.products,
        (el) => el.type === "ring" && el.ring_detail.ring_size === "ring_sizer"
      ).length
    );

    const soldData = filter(
      cart.products,
      (el) => el.type === "diamond" && el.diamond_detail["Status"] === "SOLD"
    );

    setHaveSoldDiamonds({
      status: soldData.length > 0,
      ids: soldData.map((el) => el.id),
    });

    const ringsWithDiamonds = filter(
      cart.products,
      (el) => el.type === "ring" && el.diamond_id
    );
    if (ringsWithDiamonds) {
      ringsWithDiamonds.map((el) => {
        el["diamond_detail"] = find(cart.products, {
          id: el.diamond_id,
        }).diamond_detail;
      });
    }

    const necklacesWithDiamonds = filter(
      cart.products,
      (el) => el.type === "necklace" && el.diamond_id
    );
    if (necklacesWithDiamonds) {
      necklacesWithDiamonds.map((el) => {
        el["diamond_detail"] = find(cart.products, {
          id: el.diamond_id,
        }).diamond_detail;
      });
    }

    setRingWithDiamond(ringsWithDiamonds);
    setTotalPrice(getPrice());
    setNecklaceWithDiamond(necklacesWithDiamonds);
    setIncompleteItems(getIncompleteItem());
    getShippingDate({
      preReplicaItems,
      preRingItems,
      preNecklaceItems,
      preDiamondItems,
      preRingsWithDiamonds: ringsWithDiamonds,
      preNecklacesWithDiamonds: necklacesWithDiamonds,
      preStudsItems: preStudItems,
      preBraceletItems: preBraceletItems,
      preGiftCardItems: preGiftCardItems,
    });
    /* eslint-disable-next-line */
  }, [cart]);

  const getShippingDays = (items, diamond = false) => {
    // passing diamond data instead of items - using the same key (items) so that I don't need to make a seperate configuration
    if (diamond) return diamondShipDays(items);

    for (let i = 0; i < items.length; i++) {
      // get type and return advanced date
      const { diamond_detail: diamondDetails, type, variant } = items[i];

      // for special cases where diamond is attached with a ring or necklace
      if (diamondDetails) {
        // check if eligible for quick shipping
        if (
          (diamondDetails["Cut Grade"] === "Guaranteed Brilliant" ||
            diamondDetails["Supplier ID"] === "FD") &&
          variant?.inventory_quantity >= 1
        ) {
          return quickShipDays(type);
        }

        // check for the default ship dates for both diamond and ring
        const diamondShippingDays = diamondShipDays(diamondDetails);
        const attachedSettingShippingDays = defaultShipDays(type);

        // return whichever is greater
        return diamondShippingDays > attachedSettingShippingDays
          ? diamondShippingDays
          : attachedSettingShippingDays;
      }

      // for cases like jewelry and wedding
      if (variant?.inventory_quantity >= 1) return quickShipDays(type);

      //  else return base days
      return defaultShipDays(type);
    }
  };

  // creating a validator to check whether the shipping days need to be updated or not
  const shippingDaysValidator = (shippingDays, updatedShippingDays) =>
    updatedShippingDays > shippingDays ? updatedShippingDays : shippingDays;

  const getShippingDate = async ({
    preReplicaItems,
    preRingItems,
    preNecklaceItems,
    preDiamondItems,
    preRingsWithDiamonds,
    preNecklacesWithDiamonds,
    preStudsItems,
    preBraceletItems,
    preGiftCardItems,
  }) => {
    const todayDate = convertTZ(new Date(), "America/New_York");
    let finalShippingDays = 0;

    // REPLICA --------------------------------------------------------------------

    if (preReplicaItems?.length) {
      finalShippingDays = 2;
    }

    // GIFT CARD ------------------------------------------------------------------

    if (preGiftCardItems?.length) {
      finalShippingDays = 0;
    }

    // STUD -----------------------------------------------------------------------

    if (preStudsItems?.length) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preStudsItems)
      );
    }

    // NECKLACE -------------------------------------------------------------------

    if (preNecklaceItems?.length) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preNecklaceItems)
      );
    }

    // RING -----------------------------------------------------

    if (preRingItems?.length) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preRingItems)
      );
    }

    // BRACELET -------------------------------------------------------------------

    if (preBraceletItems.length) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preBraceletItems)
      );
    }

    // DIAMOND ONLY ---------------------------------------------------------------

    for (let i = 0; i < preDiamondItems?.length; i++) {
      if (preDiamondItems[i]?.preference === "loose") {
        const diamondDetails = preDiamondItems[i]["diamond_detail"];
        finalShippingDays = shippingDaysValidator(
          finalShippingDays,
          getShippingDays(diamondDetails, true)
        );
      }
    }

    // DIAMOND WITH RING ----------------------------------------------------------

    for (let i = 0; i < preRingsWithDiamonds?.length; i++) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preRingsWithDiamonds)
      );
    }

    // DIAMOND WITH NECKLACE ------------------------------------------------------

    if (preNecklacesWithDiamonds.length) {
      finalShippingDays = shippingDaysValidator(
        finalShippingDays,
        getShippingDays(preNecklacesWithDiamonds)
      );
    }

    try {
      const { data } = await API.get(
        `https://api.frankdarling.${
          process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
            ? "com"
            : "xyz"
        }/holidays`
      );
      let holidays = 0;
      const shipDate = moment(addBusinessDays(todayDate, finalShippingDays));
      const compareDate = moment(todayDate);
      data?.rows.forEach((holiday) => {
        const startDate = moment(holiday.start_date).tz("America/New_York");
        const endDate = moment(holiday.end_date).tz("America/New_York");
        const liesInRange =
          startDate.isBetween(compareDate, shipDate) ||
          endDate.isBetween(compareDate, shipDate);
        if (liesInRange && holiday?.extended_days > holidays) {
          holidays = holiday?.extended_days;
          setWeeks(3 + Math.ceil(holidays / 7));
        }
      });
      finalShippingDays += holidays;
    } catch (err) {
      console.log("Error", err);
    }
    setShippingDate(addBusinessDays(todayDate, finalShippingDays));
  };

  const getPrice = () => {
    let price = 0;
    forEach(cart.products, (el) => {
      const currentItemPrice =
        el.type === "diamond"
          ? Number(el.diamond_detail["Cost"])
          : el.type === "ring" ||
            el.type === "necklace" ||
            el.type === "stud" ||
            el.type === "bracelet" ||
            el.type === "misc"
          ? Number(el.variant?.price)
          : el.type === "replica"
          ? Number(el.replica_detail.price)
          : 0;
      price = price + currentItemPrice;
    });

    return Number(price);
  };

  /**
   * Function to get if any items is incomplete
   * @return {boolean}
   */
  const getIncompleteItem = () => {
    const incompleteRing = filter(cart.products, (el) => {
      return (
        el.type === "ring" &&
        el.ring_detail.custom_diamond === "true" &&
        !el.diamond_id
      );
    });
    const incompleteDiamonds = filter(cart.products, (el) => {
      return (
        el.type === "diamond" &&
        !el.ring_id &&
        el.preference !== "loose" &&
        el.type === "diamond" &&
        !el.necklace_id &&
        el.preference !== "loose"
      );
    });

    const incompleteNecklace = filter(cart.products, (el) => {
      return (
        el.type === "necklace" &&
        el.necklace_detail.custom_diamond === "true" &&
        !el.diamond_id
      );
    });

    return (
      !!incompleteRing.length ||
      !!incompleteDiamonds.length ||
      !!incompleteNecklace.length
    );
  };

  const getOrCreateDiamond = async (id, image) => {
    const ROWUser = isROWUser();
    const { data, error } = await API.post(
      `/api/create-diamond-product?id=${id}&image=${image}&row=${ROWUser}`
    );
    if (error) {
      console.log("getOrCreateDiamond error", error.message);
      return false;
    }
    if (data) {
      const variantId = data.response.variants[0].id;
      return variantId;
    }
  };

  /**
   * Function for checkout
   */
  const onCheckoutClick = async () => {
    if (haveSoldDiamonds.status) {
      setContent({
        type: "sold-diamonds",
      });
      return;
    }
    setButtonLoading(true);
    const checkoutString = "https://frank-darling.myshopify.com/cart/";
    // const checkoutString =
    //   process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
    //     ? "https://frank-darling.myshopify.com/cart/"
    //     : "https://frankdarling-staging.myshopify.com/cart/";

    const cartDiamonds = filter(cart.products, { type: "diamond" });

    const variants = [];

    /**
     * Create Diamond product on shopify
     */
    for (let i = 0; i < cartDiamonds.length; i++) {
      const diamondID = cartDiamonds[i].id;
      const isCustomDiamondValid = isCustomDiamond(diamondID);
      let variantId = null;
      if (isCustomDiamondValid) {
        variantId = cartDiamonds[i].diamond_detail.VariantId;
      } else {
        const diamondImage = selectDiamondOverlayImage(
          lowerCase(cartDiamonds[i].diamond_detail["Shape"])
        );
        variantId = await getOrCreateDiamond(diamondID, diamondImage);
      }
      variants.push({ variantId, diamondID });
    }

    /**
     * All ring IDs
     */
    const cartRingIds = map(
      groupBy(filter(cart.products, { type: "ring" }), (el) => el.variant.id),
      (el, key) => {
        return `${key}:${el.length}`;
      }
    );

    /**
     * All ring replica IDs
     */
    const cartReplicaIds = map(replicaItems, (el) => {
      return `${el.id}:1`;
    });

    /**
     * All diamond IDs
     */
    const cartDiamondIds = map(
      // due to default diamonds - same diamond can repeat now, need to group and check the total count
      groupBy(variants, (el) => el.variantId),
      (el, key) => {
        return `${key}:${el.length}`;
      }
    );

    /**
     * All studs IDs
     */
    const cartStudsIds = map(
      groupBy(filter(cart.products, { type: "stud" }), (el) => el.variant.id),
      (el, key) => `${key}:${el.length}`
    );

    /**
     * All Necklace IDs
     */
    const cartNecklaceIds = map(
      groupBy(
        filter(cart.products, { type: "necklace" }),
        (el) => el.variant.id
      ),
      (el, key) => {
        return `${key}:${el.length}`;
      }
    );

    /**
     * All bracelet IDs
     */
    const braceletIds = map(
      groupBy(
        filter(cart.products, { type: "bracelet" }),
        (el) => el.variant.id
      ),
      (el, key) => `${key}:${el.length}`
    );

    /**
     * All Gift Card IDs
     */
    const giftCardIds = map(
      groupBy(filter(cart.products, { type: "misc" }), (el) => el.variant.id),
      (el, key) => `${key}:${el.length}`
    );

    /**
     * All ring with diamond attributes
     */
    const cartRingDiamondAttributes = map(
      groupBy(ringWithDiamond, (el) => el.variant.id),
      (el) => {
        return `${map(
          el,
          (subEl, key) =>
            `attributes[fd_engraving${key + 1}_${
              subEl.variant.id
            }]=${encodeURIComponent(subEl.ring_detail.engrave)}`
        ).join("&")}&${map(
          el,
          (subEl, key) =>
            `attributes[fd_size${key + 1}_${subEl.variant.id}]=${
              subEl.ring_detail.ring_size
            }`
        ).join("&")}&${map(
          el,
          (subEl, key) =>
            `attributes[fd_diamond_gia${key + 1}_${subEl.variant.id}]=D-${
              subEl.diamond_id
            }-${subEl.diamond_detail["GIA Number"]}`
        ).join("&")}`;
      }
    );

    /**
     * All Necklace with diamond attributes
     */
    const cartNecklaceDiamondAttributes = map(
      groupBy(necklaceWithDiamond, (el) => el.variant.id),
      (el) =>
        `&${map(
          el,
          (subEl, key) =>
            `attributes[fd_diamond_gia${key + 1}_${subEl.variant.id}]=D-${
              subEl.diamond_id
            }-${subEl.diamond_detail["GIA Number"]}`
        ).join("&")}`
    );

    /**
     * All ring without diamond attributes
     */
    const cartRingAttributes = map(
      groupBy(ringItems, (el) => el.variant.id),
      (el) =>
        `${map(
          el,
          (subEl, key) =>
            `attributes[fd_engraving${key + 1}_${
              subEl.variant.id
            }]=${encodeURIComponent(subEl.ring_detail.engrave)}`
        ).join("&")}&${map(
          el,
          (subEl, key) =>
            `attributes[fd_size${key + 1}_${subEl.variant.id}]=${
              subEl.ring_detail.ring_size
            }`
        ).join("&")}`
    );

    const shipDateString = `attributes[fd_ship_date]=${
      hasRingSize
        ? "TBD"
        : `${
            shippingDate?.getMonth() + 1
          }/${shippingDate?.getDate()}/${shippingDate?.getFullYear()}`
    }`;

    /**
     * All Rings Attribute String
     */
    const productAttributeString = _.chain([
      cartRingAttributes,
      cartRingDiamondAttributes,
      cartNecklaceDiamondAttributes,
      shipDateString,
    ])
      .flatten()
      .compact()
      .join("&");

    // All UTM params
    const utmString = getUtmParams()
      ? `&${utmParamString(getUtmParams())}`
      : "";

    /**
     * All Product IDs String
     */
    const productIdString = _.chain([
      cartRingIds,
      cartDiamondIds,
      cartNecklaceIds,
      cartReplicaIds,
      cartStudsIds,
      braceletIds,
      giftCardIds,
    ])
      .flatten()
      .compact()
      .join(",");

    setButtonLoading(false);

    /**
     * Final URL string
     */
    const cartURLString = `${checkoutString}${productIdString}?${productAttributeString}${utmString}`;
    window.location.href = cartURLString;
  };

  const getSimilarDiamondsPath = () => {
    if (haveSoldDiamonds?.ids?.length) {
      const diamondId = haveSoldDiamonds?.ids[0];

      const diamondData = find(
        ringWithDiamond,
        (product) => product?.diamond_id == diamondId
      );

      if (!isEmpty(diamondData)) {
        const query = {
          shape: !isEmpty(diamondData)
            ? includes(diamondData?.ring_detail?.stone, "Round Brilliant")
              ? "Round"
              : diamondData?.ring_detail?.stone
            : "",
          id: diamondData?.diamond_id,
          ring_id: diamondData?.id,
          ring_size: diamondData?.ring_detail?.ring_size,
          engrave: diamondData?.ring_detail?.engrave,
          mode: "change",
          ...(dialogType.bottom && router.query && router.query.ranMID
            ? getRakutenParams
            : {}),
        };
        const url = `/diamonds?${queryString.stringify(query)}`;
        return url;
      } else return "#";
    } else return "#";
  };

  return (
    <>
      <Cart
        {...{
          replicaItems,
          ringItems,
          diamondItems,
          ringWithDiamond,
          shippingDate,
          weeks,
          haveSoldDiamonds,
          necklaceItems,
          necklaceWithDiamond,
          studItems,
          braceletItems,
          giftCardItems,
        }}
        similarDiamondLink={getSimilarDiamondsPath()}
        maxReplicaItems={"3"}
        isEmpty={
          isEmpty(replicaItems) &&
          isEmpty(ringItems) &&
          isEmpty(diamondItems) &&
          isEmpty(ringWithDiamond) &&
          isEmpty(necklaceItems) &&
          isEmpty(necklaceWithDiamond) &&
          isEmpty(studItems) &&
          isEmpty(braceletItems) &&
          isEmpty(giftCardItems)
        }
        onRemove={({ ringId, diamondId, id, necklaceId }) =>
          removeFromCart({ ringId, diamondId, id, necklaceId })
        }
        totalPrice={totalPrice}
        isItemIncomplete={incompleteItems}
        isTryHomeIncomplete={!!(replicaItems.length && replicaItems.length < 3)}
        onCheckOut={() => onCheckoutClick()}
        checkOutLoading={buttonLoading}
        showToastMessage={showToastMessage}
        hasRingSize={hasRingSize}
      />
      {!isEmpty(content) ? (
        <ModalContainer selector="#modal">
          <LearnMoreModal
            open={true}
            handleClose={() => setContent({})}
            noBackground={false}
            content={content}
            link={getSimilarDiamondsPath()}
          />
        </ModalContainer>
      ) : null}
    </>
  );
};

CartWrapper.propTypes = {
  showToastMessage: PropTypes.bool,
};

export default CartWrapper;
