/* eslint-disable require-jsdoc */
import { useRouter } from "next/router";
import { useEffect, useContext } from "react";
import { delay } from "../../_helpers/functions";
import { AppContext } from "../../context/AppContext";

// Had to extract the script into a hook because the code doesn't get executed when the user navigates to different pages
export function useRakuten() {
  const { pathname } = useRouter();
  const { allowCookies } = useContext(AppContext);

  useEffect(() => {
    if (document && window && allowCookies) {
      // eslint-disable-next-line no-inner-declarations
      function createRakutenScript(url) {
        /* Tracking Bootstrap Set Up DataLayer objects/properties here */
        window.rakutenDataLayer = false; // Set custom DataLayer name if 'DataLayer' conflicts
        const dln = window.rakutenDataLayer || "DataLayer";
        if (!window[dln]) {
          window[dln] = {};
        }
        if (!window[dln].events) {
          window[dln].events = {};
        }
        window[dln].events.SPIVersion =
          window[dln].events.SPIVersion || "3.4.1";

        const ct = document.createElement("script");
        ct.type = "text/javascript";
        ct.async = true;
        ct.src = url;
        ct.setAttribute("id", "Rakuten_Script");

        const loc = document.getElementsByTagName("script")[0];

        loc.parentNode.insertBefore(ct, loc);
      }

      delay(2000).then(() => {
        const url = `${document.location.protocol}//tag.rmp.rakuten.com/124702.ct.js`;
        const rakutenScript = document.getElementById("Rakuten_Script");
        if (!rakutenScript) {
          createRakutenScript(url);
        }
      });
    }
  }, [pathname, allowCookies]);
  return null;
}
