import React, { useEffect, useState } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import { Round } from "../../_helpers/Icons/diamondShapes";
import Button from "../../stories/Button";
import SearchGrid from "./SearchGrid";
import Loader from "../../stories/Loader";
import AnimatedLink from "../../stories/AnimatedLink";
import { useWindowSize } from "../../hooks/useWindowSize";
import { searchClient, getProductsFromQuery } from "../../services/algolia";
import Link from "next/link";
import SearchPageProducts from "../../stories/SearchPageProducts";
import useDebounce from "../../hooks/useDebounce";
import { RightDirectionArrow } from "../../_helpers/Icons/arrowIcons";
import { CloseIcon } from "../../_helpers/Icons/miscellaneousIcons";

const queryIndex = searchClient.initIndex(
  "serverless_products_query_suggestions"
);

const defaultCategories = [
  "Engagement Ring",
  "Wedding Ring",
  "Stud Earrings",
  "Pendant Necklace",
];

const defaultSuggestions = [
  { query: "Engagement Rings", categories: ["Engagement Ring"] },
  { query: "Studs", categories: ["Stud Earrings"] },
  { query: "Wedding Rings", categories: ["Wedding Ring"] },
  { query: "Pendant Necklace", categories: ["Pendant Necklace"] },
];

const placeholderOptions = [
  "Pear Shaped Engagement Rings",
  "Emerald Cut Engagement Rings",
  "Oval Engagement Rings",
  "Cushion Cut Engagement Rings",
  "Marquise Engagement Rings",
  "Hidden Halo Engagement Rings",
  "Eternity Bands",
  "Half Bezel Engagement Ring",
  "Bezel Ring",
  "Three Stone Rings",
  "Low Profile Engagement Rings",
];

const SearchWrapper = ({ products, baseUrl, onClose }) => {
  const { width } = useWindowSize();
  const [data, setData] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [querySuggestions, setQuerySuggestions] = useState([]);
  const [loading, setLoading] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const debouncedInput = useDebounce(textInput, 500);

  useEffect(() => {
    if (width > 1024) {
      const animatePlaceholder = () => {
        setPlaceholderIndex(
          (current) => (current + 1) % placeholderOptions.length
        );
      };
      const intervalId = setInterval(animatePlaceholder, 2000);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    queryIndex.search(textInput, { hitsPerPage: 5 }).then((hits) => {
      if (isEmpty(hits.hits)) {
        setQuerySuggestions([
          { query: textInput, categories: defaultCategories },
          ...defaultSuggestions,
        ]);

        return;
      }

      const res = [];
      hits.hits.map((item, idx) => {
        // getting exact matches for categories
        const exactMatches = map(
          item.serverless_products.facets.exact_matches.product_type,
          "value"
        );
        res.push({ query: item.query, categories: exactMatches });
      });

      setQuerySuggestions(res);
    });
  }, [debouncedInput]);

  const handleInputChange = (e) => {
    setTextInput(e.target.value);
    setIsDropdownVisible(true);

    if (isEmpty(e.target.value)) {
      setLoading(null);
      setTextInput("");
      setIsDropdownVisible(false);
      setData([]);
    }

    // backspace case handling
    if (e.keyCode === 8 && isEmpty(e.target.value)) {
      setIsDropdownVisible(false);
      setTextInput("");
      setData([]);
    }
  };

  const handleSearch = async (query, categories, count) => {
    setLoading(true);
    setTextInput(query);
    setIsDropdownVisible(false);
    const response = await getProductsFromQuery(query, categories, count);
    setData(response);
    setLoading(false);
  };

  const handleClearSearch = () => {
    setTextInput("");
    setIsDropdownVisible(false);
    setData([]);
    setLoading(null);
  };

  // creating as a seperate function to use it in for different conditions
  const SearchFooter = () => (
    <div className="flex justify-center mt-8 flex-col items-center gap-y-6 text-white">
      <Round className="w-12 h-12 animate-spinSlight" />
      <h3 className="font-serif relative uppercase text-lg leading-tight lg:text-2xl lg:leading-none">
        Don&apos;t see what you are looking for?
      </h3>
      <div className="flex flex-col items-center">
        <Button
          variant="custom"
          onClick={() => {
            window.open(
              "https://frankdarling.typeform.com/to/SwTTd2?typeform-source=frankdarling.com",
              "_blank"
            );
          }}
          className="bg-theme-blue text-white hover:bg-white hover:text-theme-blue border-white px-8 py-3 w-fit"
        >
          Take our ring quiz
        </Button>
        <AnimatedLink
          className="uppercase mt-2"
          cta="Or book an appointment"
          color="white"
          href="/consultation"
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col flex-1 bg-theme-blue py-8 min-h-[calc(100vh-64px)] lg:min-h-[calc(100vh-80px)] overflow-y-scroll hide-scrollbar transition-all animate-fade-in-down navbar-margin">
      <div className="flex justify-center mx-5 md:mx-10 relative">
        <input
          value={textInput}
          onChange={handleInputChange}
          onBlur={async (e) => {}}
          onKeyPress={(e) =>
            e.key === "Enter" ? handleSearch(e.target.value) : null
          }
          className="w-full block mx-auto my-0 py-2 bg-theme-blue border-b border-white border-0 outline-none text-white text-lg lg:text-2xl uppercase transition ease-in-out delay-150 duration-300"
        />
        <p
          className={classNames(
            "pointer-events-none absolute text-lg md:text-2xl left-1 text-white/40 bottom-2 uppercase",
            textInput.length && "hidden"
          )}
        >
          {width > 1024 ? (
            <p>
              WHAT ARE YOU DREAMING ABOUT?
              <span className="text-white/80 pl-1.5">
                {placeholderOptions[placeholderIndex]}
              </span>
            </p>
          ) : (
            "Search"
          )}
        </p>
        <div className="absolute right-0 bottom-3 flex min-h-max">
          <span
            className={classNames(
              "border px-3 py-1 md:px-4 md:py-2 text-white uppercase text-sm mr-2 md:mr-3 cursor-pointer",
              !textInput.length && "hidden opacity-0"
            )}
            onClick={handleClearSearch}
          >
            clear
          </span>
          <span onClick={onClose}>
            <CloseIcon className="h-7 text-white cursor-pointer" />
          </span>
        </div>
      </div>
      {textInput.length > 0 && isDropdownVisible && (
        <div
          className={classNames(
            "text-white text-lg uppercase cursor-pointer transition-all duration-500 px-5 md:px-10",
            isDropdownVisible
              ? "block h-48 ease-linear opacity-100 animate-fade-in-down"
              : "hidden h-0 opacity-0"
          )}
        >
          {querySuggestions.map((val, i) => (
            <p
              onClick={() => handleSearch(val.query, val.categories)}
              key={i}
              className="mt-6 first:mt-8"
            >
              {val.query}
            </p>
          ))}
        </div>
      )}
      {!textInput.length && (
        <div className="flex flex-col h-full justify-between flex-auto">
          <div>
            <h3 className="font-serif col-span-18 lg:col-span-9 col-start-3 lg:col-start-3 relative uppercase text-lg leading-tight lg:text-2xl lg:leading-none text-center text-white pt-10 pb-7">
              May We Recommend
            </h3>
            <SearchPageProducts
              products={products}
              baseUrl={baseUrl}
              className="lg:w-vw-1/4 lg:h-vw-1/4 w-vw-full h-vw-full md:w-vw-1/3 md:h-vw-1/3 2xl:h-vw-1/5 2xl:w-vw-1/5 md:border-r border-b"
            />
          </div>
          <SearchFooter />
        </div>
      )}
      {!loading && !isDropdownVisible && data.length > 0 ? (
        <SearchGrid
          data={data}
          baseUrl={baseUrl}
          query={textInput}
          onClose={onClose}
          SearchFooter={SearchFooter}
        />
      ) : loading === false && !data.length ? (
        <div className="bg-theme-blue text-white text-center py-8 h-screen mt-8 text-2xl flex flex-col gap-y-2">
          <p className="block">SORRY NO RESULTS WERE FOUND</p>
          <p className="block">MAY WE SUGGEST</p>
          <Link legacyBehavior href="/search/?query=emerald cut diamonds">
            <span className="uppercase font-serif flex items-center justify-center cursor-pointer">
              EMERALD CUT DIAMONDS
              <RightDirectionArrow className="h-4 ml-1" color="white" />
            </span>
          </Link>
        </div>
      ) : !isDropdownVisible && loading !== null ? (
        <div className="bg-theme-blue text-white text-center py-8 h-screen mt-vw-1/12">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default SearchWrapper;
