/* eslint-disable no-undef */
import React, { useEffect, useContext, useState } from "react";
import { NextSeo } from "next-seo";
import PropTypes from "prop-types";
// import dynamic from "next/dynamic";

// const FooterWrapper = dynamic(() => {
//   return import("../FooterWrapper");
// });
import FooterWrapper from "../FooterWrapper";
// const HeaderWrapper = dynamic(() => {
//   return import("../HeaderWrapper");
// });
import HeaderWrapper from "../HeaderWrapper";
// const ModalWrapper = dynamic(() => {
//   return import("../ModalWrapper");
// });
// import ModalWrapper from "../ModalWrapper";
import { attributes as socialMeta } from "../../../content/socialMeta.md";
import { attributes as footData } from "../../../content/home/footer.md";
import { useGA } from "../../hooks/useGA";
import Pixel from "../pixel";
// const CartWrapper = dynamic(() => {
//   return import("../CartWrapper/CartWrapper");
// });
import CartWrapper from "../CartWrapper/CartWrapper";
// const CookieBanner = dynamic(() => {
//   return import("../../stories/CookieBanner");
// });
import CookieBanner from "../../stories/CookieBanner";
import { AppContext } from "../../context/AppContext";
import Rakuten from "../Rakuten";
import MicrosoftClarity from "../MicrosoftClarity/Clarity";
import SearchWrapper from "../SearchWrapper/SearchWrapper";
import API from "../../services/FrontEndAPI";
import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import { removeNullValuesFromObj } from "../../_helpers/functions";

const Layout = ({
  children,
  showFooter,
  headerProps,
  customMetadata = {},
  // hideModal,
  customHead,
  showScrollbar,
}) => {
  useGA();
  const { allowCookies } = useContext(AppContext);
  // search related
  const [showSearch, setShowSearch] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const router = useRouter();

  useEffect(() => {
    // get base products to showcase in search page
    const getBaseProducts = async () => {
      const { data, error } = await API.post("/api/search");

      if (!error) {
        setSearchProducts(data.products);
        setBaseUrl(data.baseUrl);

        return;
      }

      console.log("error fetching search data", error);
    };

    // don't keep calling the API if it's already called and has data
    if (!searchProducts.length) getBaseProducts();
  }, [searchProducts]);

  // for edge cases in search, if the query changes close the search modal
  useEffect(() => {
    if (showSearch) setShowSearch(false);
    // tracking UTM params and Rakuten Params from any link
    const {
      srsltid,
      fbclid,
      gclid,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      ranEAID,
      ranMID,
      ranSiteID,
    } = router.query;
    const utmParams = {
      srsltid,
      fbclid,
      gclid,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
    };
    const rakutenParams = { ranEAID, ranMID, ranSiteID };

    const filteredRakutenParams = removeNullValuesFromObj(rakutenParams);
    const filteredUtmParams = removeNullValuesFromObj(utmParams);

    if (
      typeof window !== "undefined" &&
      process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production"
    ) {
      !isEmpty(filteredUtmParams) &&
        sessionStorage.setItem("utmParams", JSON.stringify(filteredUtmParams));
      !isEmpty(filteredRakutenParams) &&
        sessionStorage.setItem(
          "rakutenParams",
          JSON.stringify(filteredRakutenParams)
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const appendHostName = (url = "") => {
    if (!url.includes("https")) {
      return `${process.env.NEXT_PUBLIC_NOW_URL}${url}`;
    }
    return url;
  };

  useEffect(() => {
    if (!showScrollbar) {
      document.body.classList.add("hide-scrollbar");
    } else {
      document.body.classList.remove("hide-scrollbar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const METADATA = {
    title:
      customMetadata.title ||
      "Frank Darling | Honest Diamond Jewelry | Free Home Try On",
    description:
      customMetadata.description ||
      "Ethically sourced and sustainably grown, diamond jewelry you can try on at home — for free. Free shipping, free returns, certified conflict-free diamonds.",
    image: customMetadata.image
      ? appendHostName(customMetadata.image)
      : appendHostName("/assets/images/meta/small-parker-headshot.jpeg"),
    url: customMetadata.url
      ? `${process.env.NEXT_PUBLIC_NOW_URL}${customMetadata.url}`
      : process.env.NEXT_PUBLIC_NOW_URL,
    type: customMetadata.type || "article",
    twitter: {
      site: socialMeta.twitter.handle || "@frankdarlingny",
      cardType: socialMeta.twitter.cardType || "summary_large_image",
    },
    local: "en_US",
    siteName: "Frank Darling | Frank Darling",
    openGraph: customMetadata.opengraph ? customMetadata.opengraph : false,
    noIndex: customMetadata.noIndex || false,
    noFollow: customMetadata.noFollow || false,
  };

  useEffect(() => {
    window.allowCookies = allowCookies;
    if (allowCookies) {
      fbq("consent", "grant");
      fbq("init", process.env.FB_PIXEL_ID);
      fbq("track", "PageView");

      window.initRakuten(
        document.location.protocol + "//tag.rmp.rakuten.com/124702.ct.js"
      );
      if (
        process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production" &&
        window.initializedGTM === undefined
      ) {
        window.initGTM(window, document, "script", "dataLayer", "GTM-TFMB8LQ");
        window.initializedGTM = true;
      }
    } else {
      fbq("consent", "revoke");
    }
  }, [allowCookies]);
  return (
    <>
      {/* <-- Next Seo Start --> */}
      <NextSeo
        title={METADATA.title}
        description={METADATA.description}
        canonical={METADATA.url}
        openGraph={
          METADATA.openGraph
            ? { ...METADATA.openGraph, url: METADATA.url }
            : {
                site_name: METADATA.siteName,
                type: METADATA.type,
                title: METADATA.title,
                description: METADATA.description,
                url: METADATA.url,
                local: METADATA.local,
                images: [{ url: METADATA.image }],
              }
        }
        twitter={METADATA.twitter}
        noindex={METADATA.noIndex}
        nofollow={METADATA.noFollow}
      />
      <Pixel name="FACEBOOK_PIXEL" />
      <Pixel name="AFFIRM_PIXEL" />
      {process.env.NEXT_PUBLIC_FRANKDARLING_ENV === "production" && (
        <MicrosoftClarity />
      )}
      <Rakuten />
      {customHead}
      <div className="absolute flex flex-col inset-0">
        {/* Header Container */}
        <div className="fixed w-full z-50">
          <HeaderWrapper
            headerProps={headerProps}
            onSearchClick={() => setShowSearch(!showSearch)}
          />
        </div>

        {/* Content and footer Container */}
        <div className="content flex flex-col mt-14 lg:mt-24">
          {/* Search Wrapper */}
          {showSearch ? (
            <SearchWrapper
              products={searchProducts}
              baseUrl={baseUrl}
              onClose={() => setShowSearch(false)}
            />
          ) : (
            <>
              <div>{children}</div>

              {/* Modal Wrapper */}
              {/* {!hideModal ? <ModalWrapper /> : null} */}

              {/* Footer Container */}
              {showFooter ? <FooterWrapper {...footData} /> : null}
            </>
          )}

          {/* Cart */}
          <CartWrapper showToastMessage={headerProps?.showToastMessage} />

          <div className="fixed bottom-0 w-full" style={{ zIndex: 2147483647 }}>
            <CookieBanner />
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  showFooter: PropTypes.bool,
  headerProps: PropTypes.object,
  customMetadata: PropTypes.object,
  // hideModal: PropTypes.bool,
  customHead: PropTypes.node,
  showScrollbar: PropTypes.bool,
};

Layout.defaultProps = {
  showFooter: true,
  // hideModal: false,
  showScrollbar: false,
};

export default Layout;
