import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const CartHeading = ({ isTryHomeIncomplete, onBack, scrollHeight = 0 }) => {
  return (
    <div className="font-sans text-theme-blue text-center">
      <h4
        className={classNames(
          "my-5 text-lg md:text-2xl 3xl:text-3xl 4xl:text-4/5xl leading-tight md:leading-tight 3xl:leading-none uppercase font-serif text-center inline-block",
          scrollHeight < 60 && "mt-20"
        )}
      >
        Your Cart
      </h4>
      <p
        onClick={onBack}
        className="my-6 text-sm leading-tight underline md:hidden text-center"
      >
        Back to shopping
      </p>
      {isTryHomeIncomplete ? (
        <p className="text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight px-8 mb-9 text-center">
          Please add three mock rings to your home try on kit to enable
          checkout.
        </p>
      ) : null}
    </div>
  );
};

CartHeading.propTypes = {
  isTryHomeIncomplete: PropTypes.bool,
  onBack: PropTypes.func,
};

export default CartHeading;
