import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import useLocalePrice from "../hooks/useLocalePrice";

const CartRingSetting = ({
  image,
  name,
  description,
  size,
  engrave,
  diamondType,
  settingPrice,
  hasStone,
  isChangeble,
  onEditSetting,
  onChangeSetting,
  isNecklace,
  ringId,
  bandWidth,
  ...rest
}) => {
  const { formatPrice } = useLocalePrice();

  const isEngraveEncoded = (engrave) => {
    // regex to validate whether the engrave is URI encoded
    const regex = /(%[0-9A-Fa-f]{2})+/g;
    return regex.test(engrave);
  };

  return (
    <div className="mt-6" {...rest}>
      <div className="flex">
        {isChangeble ? (
          <button
            onClick={() => onChangeSetting()}
            className="text-theme-blue underline text-sm mb-2 font-medium"
          >
            Change {isNecklace ? "Necklace" : "Setting"}
          </button>
        ) : null}
      </div>
      <div className="relative flex items-start">
        <div className="mr-2 w-1/2 relative overflow-hidden">
          <Image
            className="hover:scale-110 transform transition-all delay-150 ease-in"
            // loader={() => image}
            src={image}
            alt={name}
            height={1}
            width={1}
            placeholder="blur"
            blurDataURL={image}
            layout="responsive"
          />
        </div>

        <div className="font-sans w-2/3 relative text-theme-blue text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight">
          {hasStone ? (
            <div className="w-full flex justify-between">
              <p>{isNecklace ? "Necklace" : "Setting"}:</p>
              <p>{formatPrice(settingPrice)}</p>
            </div>
          ) : null}
          <p
            className="mb-3 cursor-pointer underline"
            onClick={() =>
              isChangeble ? onEditSetting("secondary") : onEditSetting()
            }
          >
            {name}
          </p>

          <p>Details:</p>
          <p>{description}</p>
          {diamondType ? <p>{diamondType}</p> : null}
          {bandWidth ? <p>{bandWidth}</p> : null}
          {isNecklace ? null : (
            <p>Size: {size === "ring_sizer" ? "Ring Sizer" : size}</p>
          )}

          {isNecklace ? null : (
            <p>
              Engraving:
              <span className="ml-1">
                {engrave
                  ? isEngraveEncoded(engrave)
                    ? decodeURIComponent(engrave)
                    : engrave
                  : "None"}
              </span>
            </p>
          )}
          <button
            onClick={() =>
              isChangeble ? onEditSetting("secondary") : onEditSetting()
            }
            className="text-theme-blue underline font-medium"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

CartRingSetting.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  diamondType: PropTypes.string,
  engrave: PropTypes.string,
  settingPrice: PropTypes.string.isRequired,
  ringId: PropTypes.string.isRequired,
  hasStone: PropTypes.bool,
  isChangeble: PropTypes.bool,
  onEditSetting: PropTypes.func,
  onChangeSetting: PropTypes.func,
};

export default CartRingSetting;
