/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import ProductCard from "./ProductCard";
import { useCart } from "../services/cart";
import { useFave } from "../services/fave";
import { getProductMetafieldData } from "../_helpers/functions";
import useSnackbar from "../hooks/useSnackbar";
import { Cart } from "../_helpers/Icons/headerIcons";
import { Carousel } from "react-responsive-carousel";
import Marquee from "react-fast-marquee";

const SearchPageProducts = ({ products, baseUrl, className }) => {
  const { cart, addToCart, isAddedToCart, removeFromCart, setShowCart } =
    useCart();
  const { addToFave, isAddedToFave, removeFromFave } = useFave();
  const { snackbar } = useSnackbar();

  const customIndicator = (onClickHandler, isSelected, index) => {
    if (isSelected) {
      return (
        <li
          className="inline-block h-2.5 w-2.5 rounded-full bg-white border border-black mx-1.5"
          aria-label={`Selected: ${index + 1}`}
        />
      );
    }
    return (
      <li
        className="inline-block h-2.5 w-2.5 rounded-full bg-theme-blue border border-white mx-1.5"
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${index + 1}`}
      />
    );
  };

  const mappedProducts = products.map((product) => {
    if (
      // use replica image only if it's available
      product.variant_details[undefined] &&
      isEmpty(product.variant_details[undefined]?.image)
    ) {
      return null;
    }
    const metaField = getProductMetafieldData(product, product?.images);

    return (
      <ProductCard
        {...product}
        baseUrl={baseUrl}
        key={product.title}
        isAddedToCart={(p) => isAddedToCart && isAddedToCart({ id: p?.id })}
        onAddToCart={(selectedVariant) => {
          if (!product.replica_metal[selectedVariant]) return;
          const data = {
            type: "replica",
            ring_detail: {
              title: product.title,
            },
            variant: product.variant_details[selectedVariant],
            replica_detail: product.replica_metal[selectedVariant],
            id: product.replica_metal[selectedVariant].id,
          };
          if (
            !isAddedToCart({
              id: product.replica_metal[selectedVariant].id,
            })
          ) {
            const previousReplicas = filter(cart.products, {
              type: "replica",
            });
            if (previousReplicas.length < 3) {
              addToCart(data);
              setShowCart({ value: true, showId: "" });
            } else {
              snackbar({
                message:
                  "You already have 3 Try at home rings in your cart. Remove one of them to add this to your cart.",
                variant: "error",
                dismissTime: 4000,
                icon: <Cart className="w-full h-full text-white" />,
              });
            }
          } else {
            removeFromCart({
              id: product.replica_metal[selectedVariant].id,
            });
          }
        }}
        onAddToFaved={() => {
          if (isAddedToFave({ ringId: product.id })) {
            removeFromFave({ ringId: product.id });
          } else {
            addToFave({ product, type: "ring" });
          }
        }}
        isFaved={isAddedToFave && isAddedToFave({ ringId: product.id })}
        hoverMetafieldData={metaField?.hoverImages}
        defaultMetal={metaField?.defaultMetal}
        ringDetails={metaField?.ringDetails}
        productType={product.product_type}
        className={className}
        isSearch
      />
    );
  });

  return (
    <div>
      {/* for mobile */}
      <div className="flex w-full md:hidden">
        <Carousel
          className="flex items-stretch w-full"
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          transitionTime={500}
          showIndicators={true}
          autoPlay
          preventMovementUntilSwipeScrollTolerance={true}
          renderIndicator={customIndicator}
        >
          {mappedProducts}
        </Carousel>
      </div>
      {/* for web */}
      <div className="hidden flex-wrap md:flex">
        <Marquee pauseOnHover>{mappedProducts}</Marquee>
      </div>
    </div>
  );
};

SearchPageProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Center title of the section
       */
      title: PropTypes.string,
      /**
       * Whether its setting only or whole item
       */
      productType: PropTypes.string,
      /**
       * Description
       */
      description: PropTypes.string,
      /**
       * price of product
       */
      price: PropTypes.number,
      /**
       *other classnames to be added
       */
      className: PropTypes.string,
      /**
       * Whether product is added to cart already or not
       */
      isAddedToCart: PropTypes.bool,
      /**
       * Whether product is faved already or not
       */
      isFaved: PropTypes.bool,
      /**
       * variants: list of colors
       */
      variant_details: PropTypes.object.isRequired,
      /**
       * Onclick function for cart
       */
      onAddToCart: PropTypes.func,
      /**
       * Onclick function for wishlist/fav
       */
      onAddToFaved: PropTypes.func,
    })
  ),
  storeFrontProductData: PropTypes.object,
  /**
   * external modifications in css
   */
  className: PropTypes.string,
};

export default SearchPageProducts;
