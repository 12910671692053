import React, { useContext } from "react";
import PropTypes from "prop-types";
import useDialog from "../hooks/useDialog";
import classNames from "classnames";
import { AppContext } from "../context/AppContext";

const CookieBanner = ({ text }) => {
  const { dialogType, setDialogType } = useDialog();
  const { setAllowCookies } = useContext(AppContext);

  const onClick = (res) => {
    setDialogType({ ...dialogType, bottom: false });
    setAllowCookies(res === "allow");
    const data = { "set-cookie": res };
    window.localStorage.setItem("frankdarling-cookie", JSON.stringify(data));
  };

  return (
    <div
      className={classNames(
        dialogType.bottom ? "block" : "hidden",
        ` bg-theme-blue text-white transition-all duration-500 overflow-hidden px-7 py-4`
      )}
    >
      <div className="flex flex-row justify-between items-center group space-x-3">
        <h3 className="text-xs block flex-1 focus:outline-none uppercase">
          {text}
        </h3>
        <div className="flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 text-xs">
          <button
            className="cookie-deny-button text-white border border-white px-4 sm:px-9 py-2"
            onClick={() => onClick("deny")}
          >
            DENY
          </button>
          <button
            className=" text-theme-blue bg-white px-4 sm:px-9 py-2 hover:bg-gray-300"
            onClick={() => onClick("allow")}
          >
            ALLOW
          </button>
        </div>
      </div>
    </div>
  );
};

CookieBanner.defaultProps = {
  text: "This site uses cookies (not the chocolate kind) to store preferences and personalize our marketing. By clicking allow, you consent to use our cookies.",
};

CookieBanner.propTypes = {
  text: PropTypes.string,
};

export default CookieBanner;
