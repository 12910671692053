import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";

const CartStudSetting = ({
  image,
  name,
  description,
  diamondType,
  caratWeight,
  braceletLength,
  amount,
  isChangeble,
  onEditSetting,
  studId,
  ...rest
}) => {
  return (
    <div className="mt-6" {...rest}>
      <div className="relative flex items-start">
        <div className="mr-2 w-1/2 relative overflow-hidden">
          <Image
            className="hover:scale-110 transform transition-all delay-150 ease-in"
            // loader={() => image}
            src={image}
            alt={name}
            height={1}
            width={1}
            placeholder="blur"
            blurDataURL={image}
            layout="responsive"
          />
        </div>

        <div className="font-sans w-2/3 relative text-theme-blue text-sm 3xl:text-lg 4xl:text-2xl leading-tight 3xl:leading-snug 4xl:leading-tight">
          <p
            className="mb-3 cursor-pointer underline"
            onClick={() => {
              isChangeble ? onEditSetting("secondary") : onEditSetting();
            }}
          >
            {name}
          </p>
          {!amount ? (
            <>
              <p>Details:</p>
              <p>{description}</p>
            </>
          ) : null}
          {diamondType ? <p>{diamondType}</p> : null}
          {caratWeight ? <p>Carat: {caratWeight}</p> : null}
          {/* adding one field to support the bracelet configuration, avoiding creating a new component */}
          {braceletLength ? <p>Total Length: {braceletLength}</p> : null}
          {/* adding one field to support the gift card configuration, avoiding creating a new component */}
          {amount ? <p>Amount: ${amount}</p> : null}
          <button
            onClick={() => {
              isChangeble ? onEditSetting("secondary") : onEditSetting();
            }}
            className="text-theme-blue underline font-medium"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

CartStudSetting.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  diamondType: PropTypes.string,
  caratWeight: PropTypes.string,
  isChangeble: PropTypes.bool,
  onEditSetting: PropTypes.func,
  studId: PropTypes.string.isRequired,
};

export default CartStudSetting;
