import { useEffect, useContext } from "react";
import { initGA, logPageView } from "../_helpers/analytics";
import { AppContext } from "../context/AppContext";

/**
 * Function for Google Analytics
 */
export function useGA() {
  const { allowCookies } = useContext(AppContext);
  useEffect(() => {
    if (allowCookies) {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
    } else {
      if (window.GA_INITIALIZED) {
        window.GA_INITIALIZED = false;
        window["ga-disable-${process.env.GA_ANALYTICS_ID}"] = true;
      }
    }
  }, [allowCookies]);
}
