/* eslint-disable require-jsdoc */
import { useRouter } from "next/router";
import { useEffect, useContext } from "react";
import { delay } from "../../_helpers/functions";
import { AppContext } from "../../context/AppContext";

// Had to extract the script into a hook because the code doesn't get executed when the user navigates to different pages
export function useClarity() {
  const { pathname } = useRouter();
  const { allowCookies } = useContext(AppContext);

  useEffect(() => {
    if (document && window && allowCookies) {
      // eslint-disable-next-line no-inner-declarations
      function createClarityScript() {
        (function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              // eslint-disable-next-line prefer-rest-params
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          t.setAttribute("id", "Clarity_Script");
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "c9zv9qwbzk");
      }

      delay(2000).then(() => {
        const rakutenScript = document.getElementById("Clarity_Script");
        if (!rakutenScript) {
          createClarityScript();
        }
      });
    }
  }, [pathname, allowCookies]);
  return null;
}
