import React from "react";
import Head from "next/head";
import { useClarity } from "./useClarity";

const MicrosoftClarity = () => {
  useClarity();
  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.setAttribute('id', 'Clarity_Script');
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "c9zv9qwbzk");`,
        }}
      />
    </Head>
  );
};

MicrosoftClarity.propTypes = {};

export default MicrosoftClarity;
