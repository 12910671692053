import PropTypes from "prop-types";

export const Arrow = ({ color, className }) => (
  <svg className={className} viewBox="0 0 9 16" fill="none">
    <path d="M1 1L7 8L0.999999 15" stroke={color} strokeWidth="2" />
  </svg>
);

Arrow.defaultProps = {
  color: `white`,
  className: "",
};
Arrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export const LeftEndArrow = ({ color, className }) => (
  <svg className={className} viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.707 12.5 19 5.207l-1.414-1.414L8.879 12.5l8.707 8.707L19 19.793 11.707 12.5ZM6 3.5H4v18h2z"
      fill={color}
    />
    <rect
      width="2"
      height="18"
      transform="matrix(-1 0 0 1 6 3.5)"
      fill={color}
    />
  </svg>
);

LeftEndArrow.defaultProps = {
  color: "#1C1794",
  className: "",
};
LeftEndArrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export const RightEndArrow = ({ color, className }) => (
  <svg className={className} viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.293 12.5 5 5.207l1.414-1.414 8.707 8.707-8.707 8.707L5 19.793l7.293-7.293ZM18 3.5h2v18h-2z"
      fill={color}
    />
    <rect x="18" y="3.5" width="2" height="18" fill={color} />
  </svg>
);

RightEndArrow.defaultProps = {
  color: "#1C1794",
  className: "",
};
RightEndArrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export const LeftArrow = ({ color, className }) => (
  <svg className={className} viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m9.414 12.5 7.293 7.293-1.414 1.414L6.586 12.5l8.707-8.707 1.414 1.414L9.414 12.5Z"
      fill={color}
    />
  </svg>
);

LeftArrow.defaultProps = {
  color: "#1C1794",
  className: "",
};
LeftArrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export const RightArrow = ({ color, className }) => (
  <svg className={className} viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.586 12.5 7.293 5.207l1.414-1.414 8.707 8.707-8.707 8.707-1.414-1.414 7.293-7.293Z"
      fill={color}
    />
  </svg>
);

RightArrow.defaultProps = {
  color: "#1C1794",
  className: "",
};
RightArrow.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
export const ArrowLongLeft = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.25736 9.74264L10.5 5.5L6.25736 1.25736" />
    <path d="M10.5 5.5L0 5.5" />
  </svg>
);

ArrowLongLeft.defaultProps = {
  color: "stroke-current stroke-1",
  className: "",
};
ArrowLongLeft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

//

export const ArrowLeft = ({ color, className }) => (
  <svg className={className} viewBox="0 0 6 9" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.707 4.5 5.354.854 4.646.146.293 4.5l4.353 4.354.708-.708L1.707 4.5Z"
      fill={color}
    />
  </svg>
);

ArrowLeft.defaultProps = {
  color: `white`,
  className: "",
};
ArrowLeft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export const RightDirectionArrow = ({ className, color }) => (
  <svg
    className={className}
    width="44"
    height="19"
    viewBox="0 0 44 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_12941_41392)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 5.39957V5.63864C37.2049 6.61883 35.388 8.38796 33.8101 10.2049L33.332 9.75066C33.8818 8.96173 34.5751 8.1967 35.2923 7.45558C35.5075 7.24042 35.627 6.97744 35.627 6.78618C35.627 6.54711 35.4358 6.30804 35.0055 6.30804H4.5V4.73017H35.0294C35.2684 4.73017 35.651 4.44329 35.651 4.20422C35.651 3.98905 35.5553 3.84561 35.3163 3.60654C34.623 2.93714 33.8818 2.07648 33.3559 1.28755L33.834 0.833313C35.388 2.62635 37.2049 4.44329 39.5 5.39957Z"
        fill={`${color ? color : "white"}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 5.39957V5.63864C37.2049 6.61883 35.388 8.38796 33.8101 10.2049L33.332 9.75066C33.8818 8.96173 34.5751 8.1967 35.2923 7.45558C35.5075 7.24042 35.627 6.97744 35.627 6.78618C35.627 6.54711 35.4358 6.30804 35.0055 6.30804H4.5V4.73017H35.0294C35.2684 4.73017 35.651 4.44329 35.651 4.20422C35.651 3.98905 35.5553 3.84561 35.3163 3.60654C34.623 2.93714 33.8818 2.07648 33.3559 1.28755L33.834 0.833313C35.388 2.62635 37.2049 4.44329 39.5 5.39957Z"
        stroke={`${color ? color : "black"}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 5.39957V5.63864C37.2049 6.61883 35.388 8.38796 33.8101 10.2049L33.332 9.75066C33.8818 8.96173 34.5751 8.1967 35.2923 7.45558C35.5075 7.24042 35.627 6.97744 35.627 6.78618C35.627 6.54711 35.4358 6.30804 35.0055 6.30804H4.5V4.73017H35.0294C35.2684 4.73017 35.651 4.44329 35.651 4.20422C35.651 3.98905 35.5553 3.84561 35.3163 3.60654C34.623 2.93714 33.8818 2.07648 33.3559 1.28755L33.834 0.833313C35.388 2.62635 37.2049 4.44329 39.5 5.39957Z"
        stroke={`${color ? color : "black"}`}
        strokeOpacity="0.2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_12941_41392"
        x="0"
        y="0.110352"
        width="44"
        height="18.8172"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12941_41392"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_12941_41392"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

RightDirectionArrow.defaultProps = {
  className: "",
};

RightDirectionArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export const ArrowRigthShort = ({ className, color }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6548 8.83334H0.5V7.16667H12.6548L7.41074 1.92259L8.58925 0.74408L15.8452 8L8.58925 15.2559L7.41074 14.0774L12.6548 8.83334Z"
      fill={`${color ? "white" : "#1C1794"}`}
    />
  </svg>
);

ArrowRigthShort.defaultProps = {
  className: "",
};

ArrowRigthShort.propTypes = {
  className: PropTypes.string,
};

const ArrowIcons = {
  Arrow,
  LeftArrow,
  LeftEndArrow,
  RightArrow,
  RightEndArrow,
  ArrowLongLeft,
  ArrowLeft,
  RightDirectionArrow,
  ArrowRigthShort,
};

export default ArrowIcons;
