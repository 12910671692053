import React from "react";

/**
 * Function to call on outside click
 * @param {function} onOutsideClick Function to call on outside click
 * @param {boolean} isActive Current active of container ref
 * @return {object}
 */
export function useOutsideClick(onOutsideClick, isActive) {
  const containerRef = React.useRef();
  const handler = React.useRef();
  handler.current = onOutsideClick;

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (!isActive) {
      return;
    }

    /**
     *
     * @param {object} e
     */
    function listener(e) {
      if (containerRef?.current && !containerRef?.current?.contains(e.target)) {
        handler.current();
      }
    }

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [isActive]);

  return containerRef;
}
