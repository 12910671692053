import React from "react";
import Head from "next/head";
import { useRakuten } from "./useRakuten";

const Rakuten = () => {
  useRakuten();
  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          function initRakuten(url) {
            /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
            window.rakutenDataLayer = false; // Set custom DataLayer name if 'DataLayer' conflicts
            var dln = window.rakutenDataLayer || 'DataLayer';
            if (!window[dln]) {
                window[dln] = {};
            }
            if (!window[dln].events) {
                window[dln].events = {};
            }
            window[dln].events.SPIVersion = window[dln].events.SPIVersion || "3.4.1";
        
            var loc, ct = document.createElement("script");
            ct.type = "text/javascript";
            ct.async = true;
            ct.src = url;
            ct.setAttribute('id', 'Rakuten_Script')

            loc = document.getElementsByTagName('script')[0];
            loc.parentNode.insertBefore(ct, loc);
        }
        `,
        }}
      />
    </Head>
  );
};

export default Rakuten;
