import React from "react";
import Link from "next/link";
import { useCart } from "../services/cart";
import { useRouter } from "next/router";
import queryString from "query-string";
import useDialog from "../hooks/useDialog";
import classNames from "classnames";
import { getRakutenParams } from "../_helpers/functions";

const CartEmptyState = ({ scrollHeight = 0 }) => {
  const { setShowCart } = useCart();
  const router = useRouter();
  const { dialogType } = useDialog();

  return (
    <div
      className={classNames(
        "px-8 font-sans text-theme-blue text-center",
        scrollHeight > 60 ? "pt-24" : "pt-24 lg:pt-32"
      )}
    >
      <h4 className="mb-8 text-lg md:text-2xl 3xl:text-3xl 4xl:text-4/5xl leading-tight md:leading-tight 3xl:leading-none uppercase">
        Your cart is currently empty
      </h4>
      <p className="text-lg 3xl:text-2xl 4xl:text-3xl leading-6 3xl:leading-tight">
        <Link
          legacyBehavior
          href={`/wishlist?${queryString.stringify({
            ...(dialogType.bottom && router.query && router.query.ranMID
              ? getRakutenParams
              : {}),
          })}`}
        >
          <a
            className="underline hover:no-underline"
            onClick={() => setShowCart({ value: false, showId: "" })}
          >
            View your wishlist
          </a>
        </Link>{" "}
        or{" "}
        <Link
          legacyBehavior
          href={`/engagement-rings?${queryString.stringify({
            ...(dialogType.bottom && router.query && router.query.ranMID
              ? getRakutenParams
              : {}),
          })}`}
        >
          <a
            className="underline hover:no-underline"
            onClick={() => setShowCart({ value: false, showId: "" })}
          >
            view all rings.
          </a>
        </Link>
      </p>
    </div>
  );
};

export default CartEmptyState;
