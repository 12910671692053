import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import FACEBOOK_PIXEL from "./facebook";
import AFFIRM_PIXEL from "./affirm";

const Pixel = ({ name }) => {
  return (
    <Head>
      {name === "FACEBOOK_PIXEL" && <FACEBOOK_PIXEL />}
      {name === "AFFIRM_PIXEL" && <AFFIRM_PIXEL />}
    </Head>
  );
};

Pixel.propTypes = {
  name: PropTypes.string,
};

export default Pixel;
