import algoliasearch from "algoliasearch";
import map from "lodash/map";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { getProductsFromStoreFront } from "./shopify";

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_APPLICATION_ID,
  process.env.NEXT_PUBLIC_SEARCH_ONLY_API_KEY
);

const defaultSearchCategories = [
  "Engagement Ring",
  "Wedding Ring",
  "Stud Earrings",
  "Pendant Necklace",
];

export const getProducts = async (query, categories, count = 16) => {
  const filter = compact(
    map(
      !isEmpty(categories) ? categories : defaultSearchCategories,
      (c, idx) => {
        // adding conditions to avoid unnecessary data and improve filtering
        if (c == "Diamond" || c == "undefined") return `NOT product_type:${c}`;

        return `product_type:${c}`;
      }
    )
  );
  const { results } = await searchClient.search([
    {
      indexName: "serverless_products",
      params: {
        hitsPerPage: count,
        facetFilters: [filter],
      },
      query: `${query}`,
    },
  ]);

  return results;
};

export const getProductsFromQuery = async (query, categories, count = 16) => {
  const returnedData = await getProducts(query, categories, count);
  const hits = returnedData[0]?.hits;
  const algoMappedData = hits?.map((el) => el.id);
  return await getProductsFromStoreFront(algoMappedData);
};
